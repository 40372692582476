import React, { useEffect, useState } from 'react'
import { Switch, Route, useLocation } from 'react-router-dom'
import ProductList from './ProductList'
import ProductDetail from './ProductDetail'
import PageHeader from './PageHeader'
import HomePage from './HomePage'
import PageFooter from './PageFooter'
import Checkout from './Checkout'
import ScrollToTop from './ScrollToTop'
import { connect } from 'react-redux'
import { PropTypes } from 'prop-types'
import { fetchCartItems, setIsInitFinish, fetchWebsiteInfo, fetchCategory, setIsTop } from './actions'
import {
    TransitionGroup,
    CSSTransition
} from 'react-transition-group'
import loadingGif from './img/Grocery-cart-Loader.gif'
import _get from 'lodash/get'
import ContactUs from './ContactUs'
import WebMaintainPage from './WebMaintainPage'
import OrderSearch from './OrderSearch'
import OrderSearchResult from './OrderSearchResult'
import MetaTags from 'react-meta-tags'

function App(props) {
    const [isShowLoadingPage, setIsShowLoadingPage] = useState(true)
    const [showMaintainPage, setShowMaintainPage] = useState(false)
    const location = useLocation()

    useEffect(() => {
        Promise.all([props.fetchCartItems(), props.fetchWebsiteInfo(), props.fetchCategory()])
            .then(() => {
                setTimeout(() => setIsShowLoadingPage(false), 500)
            })
            .catch(error => {
                console.error('init failed', error)
                props.setIsInitFinish(true)
                setIsShowLoadingPage(false)
                setShowMaintainPage(true)
            })
        const onScroll = (e) => {
            if (e.target.offsetWidth > 767) {
                return
            }

            if (window.scrollY < 40) {
                props.setIsTop(true)
            } else {
                props.setIsTop(false)
            }
        }
        window.addEventListener("scroll", onScroll)
        return () => window.removeEventListener("scroll", onScroll)
    }, [])

    useEffect(() => {
        if (!props.isLoading) {
            setTimeout(() => setIsShowLoadingPage(false), 500)
        } else {
            setIsShowLoadingPage(true)
        }
    }, [props.isLoading])

    useEffect(() => {
        if (!props.websiteInfo || !props.websiteInfo.logo_url) {
            return
        }
        var link = document.querySelector("link[rel~='icon']")
        if (!link) {
            link = document.createElement('link')
            link.rel = 'icon'
            document.getElementsByTagName('head')[0].appendChild(link)
        }
        link.href = props.websiteInfo.logo_url
        props.setIsInitFinish(true)
    }, [props.websiteInfo])

    if (showMaintainPage) {
        return <WebMaintainPage />
    }

    return (
        <>
            <MetaTags>
                <title>{_get(props, 'websiteInfo.name', '')}</title>
                <meta name="description" content={_get(props, 'websiteInfo.og.description', '')} />
                <meta property="og:title" content={_get(props, 'websiteInfo.og.title', '')} />
                <meta property="og:image" content={_get(props, 'websiteInfo.og.image', '')} />
            </MetaTags>
            <CSSTransition
                in={isShowLoadingPage}
                timeout={1000}
                classNames="loading-page"
                unmountOnExit
            >
                <div id="loading-wrapper" style={{ opacity: '.5!important' }}>
                    <img src={loadingGif} />
                </div>
            </CSSTransition>
            <ScrollToTop />
            <PageHeader />
            <TransitionGroup className='main-content'>
                <CSSTransition
                    classNames="page"
                    timeout={300}
                    key={location.pathname}
                >
                    <Switch location={location}>
                        <Route path='/productList/cate_id=:oicId/:categoryName+' exact component={ProductList} />
                        <Route path='/productList/search/:keywords+' exact component={ProductList} />
                        <Route path='/productDetail/:id' component={ProductDetail} />
                        <Route path='/checkout/:orderId?' component={Checkout} />
                        <Route path='/orderStatus/:searchTypeNum/:orderId' exact component={OrderSearchResult} />
                        <Route path='/orderSearch/' exact component={OrderSearch} />
                        <Route path='/contactUs/' exact component={ContactUs} />
                        <Route path='*' component={HomePage} />
                    </Switch>
                </CSSTransition>
            </TransitionGroup>
            <PageFooter />
            {!props.rwdInfo.isTop && <i id='scrollUp' className="fa fa-angle-up" onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })} />}
        </>
    )
}

ProductList.propTypes = {
    productList: PropTypes.arrayOf(PropTypes.object).isRequired,
    cart: PropTypes.object.isRequired
}

const mapStateToProps = (state) => {
    const { productList, cart, isLoading, isInitFinish, rwdInfo, websiteInfo } = state
    return {
        productList,
        cart,
        isLoading,
        isInitFinish,
        rwdInfo,
        websiteInfo
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        fetchCartItems: () => {
            return new Promise((resolve, reject) => {
                dispatch(fetchCartItems())
                    .then(() => {
                        resolve()
                    })
            })
        },
        fetchWebsiteInfo: () => {
            return dispatch(fetchWebsiteInfo())
        },
        fetchCategory: () => {
            return dispatch(fetchCategory())
        },
        setIsInitFinish: (status) => {
            dispatch(setIsInitFinish(status))
        },
        setIsTop: (status) => {
            dispatch(setIsTop(status))
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(App)
