import React, { useState, useEffect } from 'react'
import {
    fetchProductListByGroup,
    fetchProductListByKeywords,
    fetchCartItems
} from './actions'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { useParams } from 'react-router-dom'
import ProductListItem from './ProductListItem'
import _get from 'lodash/get'
import LoadingBlock from './LoadingBlock'
import Pagination from 'react-bootstrap/Pagination'
import NiceSelect from './NiceSelect'
import _orderBy from 'lodash/orderBy'
import ProductBuyingModal from './ProductBuyingModal'

const sortData = {
    optionList: [
        '價格：由高到低',
        '價格：由低到高',
    ],
    sortType: [
        {
            type: ['asc'],
            attrs: ['oig_id']
        },
        {
            type: ['desc', 'desc'],
            attrs: ['price_max', 'price_min']
        }, {
            type: ['asc', 'asc'],
            attrs: ['price_min', 'price_max']
        }
    ],
    defaultValue: '商品排序'
}

const displayNumData = {
    optionList: [
        '每頁顯示24個',
        '每頁顯示72個',
    ],
    numList: [
        12, 24, 72
    ],
    defaultValue: '每頁顯示12個'
}

function ProductList(props) {
    const { oicId, keywords, categoryName } = useParams()
    const [listStatus, setListStatus] = useState('loading')
    const [currentPageNum, setCurrentPageNum] = useState(1)
    const [totalPageNum, setTotalPageNum] = useState(1)
    const [productNumPerPage, setProductNumPerPage] = useState(12)
    const [sortType, setSortType] = useState(sortData.sortType[0])
    const [showBuyingModal, setShowBuyingModal] = useState(false)
    const [selectedProduct, setSelectedProduct] = useState({})

    useEffect(() => {
        props.fetchProductList(Number(oicId) || null, keywords)
            .then(() => {
                setListStatus('done')
            })
            .catch(() => {
                setListStatus('done')
            })
    }, [])

    useEffect(() => {
        if (!props.productList || props.productList.length === 0) {
            return
        }
        setCurrentPageNum(1)
        setTotalPageNum(Math.ceil(props.productList.length / productNumPerPage))
    }, [props.productList, productNumPerPage])

    const beforeAddItemToCart = (product) => {
        openBuyingModal(product)
    }

    const openBuyingModal = (product) => {
        setSelectedProduct(product)
        setShowBuyingModal(true)
    }

    const closeBuyingModal = (product) => {
        setShowBuyingModal(false)
    }

    const handleSortChange = (index) => {
        setSortType(sortData.sortType[index])
    }

    const handleDisplayNumChange = (index) => {
        setProductNumPerPage(displayNumData.numList[index])
    }

    const renderProductList = () => {
        const sortedProductList = _orderBy(props.productList, sortType.attrs, sortType.type)
        const start = (currentPageNum - 1) * productNumPerPage
        const end = start + productNumPerPage
        const productListDisplay = sortedProductList.slice(start, end).map(product => {
            return (
                <ProductListItem beforeAddItemToCart={beforeAddItemToCart} product={product} className='col-6 col-md-4 col-lg-3 pl-2 pr-2 d-flex justify-content-center' />
            )
        })
        return productListDisplay
    }

    const renderPagination = () => {
        const pageRange = props.rwdInfo.deviceWidth === 'sm' ? 1 : 2
        let start = currentPageNum - pageRange
        let end = currentPageNum + pageRange

        if (end > totalPageNum) {
            start -= (end - totalPageNum)
            end = totalPageNum
        }
        if (start <= 0) {
            end += ((start - 1) * (-1))
            start = 1
        }

        end = end > totalPageNum ? totalPageNum : end

        const paginationDisplay = []
        const handlePageChange = (pageNum) => () => {
            if (pageNum === currentPageNum) {
                return
            }
            setCurrentPageNum(pageNum)
        }

        for (let i = start; i <= end; i++) {
            paginationDisplay.push(
                <Pagination.Item active={i === currentPageNum} onClick={handlePageChange(i)}>{i}</Pagination.Item>
            )
        }
        return (
            <section className='d-flex justify-content-center mb-5'>
                <Pagination>
                    <Pagination.First disabled={currentPageNum === 1} onClick={handlePageChange(1)} />
                    <Pagination.Prev disabled={currentPageNum === 1} onClick={handlePageChange(currentPageNum - 1)} />
                    {paginationDisplay}
                    <Pagination.Next disabled={currentPageNum === totalPageNum} onClick={handlePageChange(currentPageNum + 1)} />
                    <Pagination.Last disabled={currentPageNum === totalPageNum} onClick={handlePageChange(totalPageNum)} />
                </Pagination>
            </section>
        )
    }

    return (
        <div className='product-list'>
            <ProductBuyingModal isShow={showBuyingModal} product={selectedProduct} onClose={closeBuyingModal} />
            {/*<section className='section-pagetop bg'>
         feature - path
          <div className='container'>
            <h2 className='title-page'>Category products</h2>
            <nav>
              <ol className='breadcrumb text-white'>
                <li className='breadcrumb-item'>
                  <a href='#'>Home</a>
                </li>
                <li className='breadcrumb-item'>
                  <a href='#'>Best category</a>
                </li>
                <li className='breadcrumb-item active' aria-current='page'>
                  Great articles
                </li>
              </ol>
            </nav>
          </div>
      </section>*/}
            <section className='section-content'>
                <header className='pt-3 mb-4 pb-3 product-list-header container'>
                    <div className='product-list-title'>
                        {categoryName}
                    </div>
                    <div className='select-container'>
                        <NiceSelect {...sortData} onChange={handleSortChange} />
                        <NiceSelect {...displayNumData} onChange={handleDisplayNumChange} />
                        {/*feature - change list style
              <div className='btn-group'>
                <a
                  href='#'
                  className='btn btn-outline-secondary'
                  data-toggle='tooltip'
                  title='List view'
                >
                  <i className='fa fa-bars'></i>
                </a>
                <a
                  href='#'
                  className='btn  btn-outline-secondary active'
                  data-toggle='tooltip'
                  title='Grid view'
                >
                  <i className='fa fa-th'></i>
                </a>
            </div>*/}
                    </div>
                </header>
                <div className='container'>
                    <main className='col-md-12'>
                        {
                            listStatus === 'loading' ? <LoadingBlock /> :
                                listStatus === 'done' && props.productList.length === 0 ?
                                    <div className="d-flex justify-content-center min-vh-40 align-items-center">
                                        {keywords && keywords.length > 0 ? <span>無符合關鍵字的商品</span> : <span>目前尚無商品</span>}
                                    </div> :
                                    <div className='row'>
                                        {renderProductList()}
                                    </div>
                        }
                    </main>
                </div>
            </section>
            {renderPagination()}
        </div>
    )
}

ProductList.propTypes = {
    productList: PropTypes.arrayOf(PropTypes.object).isRequired,
    fetchProductList: PropTypes.func.isRequired,
    cart: PropTypes.object.isRequired
}

const mapStateToProps = state => {
    const { productList, cart, rwdInfo } = state
    return {
        productList,
        cart,
        rwdInfo
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        fetchProductList: (oicId, keywords) => {
            return new Promise((resolve, reject) => {
                if (keywords) {
                    dispatch(fetchProductListByKeywords(keywords))
                        .then(() => {
                            resolve()
                        })
                } else {
                    dispatch(fetchProductListByGroup(oicId))
                        .then(() => {
                            resolve()
                        })
                }
            })
        },
        fetchCartItems: () => {
            dispatch(fetchCartItems())
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductList)
