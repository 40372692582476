import React from 'react'
import PropTypes from 'prop-types'

function StatusBar (props) {
  return (
    <div className='checkout-progress-bar'>
      {
        props.steps.map((step, index) => {
          return (
            <div className={props.currentStep === index + 1 ? 'progress-step is-active' : 'progress-step'}>
              <div className='step-count'></div>
              <div className='step-description'>{step.name}</div>
            </div>
          )
        })
      }
    </div>
  )
}

StatusBar.propTypes = {
  steps: PropTypes.arrayOf(PropTypes.object),
  currentStep: PropTypes.number,
  showNumber: PropTypes.bool
}

export default StatusBar
