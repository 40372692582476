import React, {useState} from 'react'
import { history, useHistory } from 'react-router-dom'
import _get from 'lodash/get'
import propTypes from 'prop-types'
import SmoothRenderImage from './SmoothRenderImage'
import { connect } from 'react-redux'

function ProductListItem (props) {
  const { product, beforeAddItemToCart } = props
  const history = useHistory()
  const [ status, setStatus ] = useState('loaded')

  const handleClick = () => {
     if (props.preventClick) {
       return
     }
    window.scrollTo({ top: 0 })
    history.push(`/productDetail/${product.oig_id}`)
  } 
  const addPriceDelimiter = (number) => {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  return (
    <div className={props.className} onClick={handleClick}>
      <figure className='card card-product-grid border-0'>
        <div className='img-wrap'>
          {/* <span className='badge badge-danger'> NEW </span> */}
          <SmoothRenderImage
            src={!!product.images.length ? `${product.images}` : ''}
            alt=''
          />
          {props.rwdInfo.deviceWidth !== 'sm' && <div className='product-image-overlay'>
              <button
                className="btn"
                onClick={event => {
                  event.preventDefault()
                  event.stopPropagation()
                  beforeAddItemToCart(product)
              }}>
                <span className='material-icons'>add_shopping_cart</span>
              </button>
          </div>}
        </div>
        <figcaption className='info-wrap'>
          <div className='fix-height'>
            <div className='title'>{product.group_name}</div>
            <div className='price-wrap mt-2'>
              <div className='price'>{
                product.price_min === product.price_max ?
                `$${addPriceDelimiter(product.price_min)}` :
                `$${addPriceDelimiter(product.price_min)}~$${addPriceDelimiter(product.price_max)}`
              }</div>
              {
                _get(product, 'price_original_max', 0) > 0 ?
                  <del className='price-old'>
                    ${addPriceDelimiter(product.price_original_max)}
                  </del> :
                  null
              }
            </div>
          </div>
          {props.rwdInfo.deviceWidth === 'sm' &&
            <button
              className="btn"
              onClick={event => {
                event.preventDefault()
                event.stopPropagation()
                beforeAddItemToCart(product)
            }}>
              <span className='material-icons'>add_shopping_cart</span>
            </button>}
          {/*<div
            className='btn btn-block btn-primary'
            onClick={event => {
              event.preventDefault()
              event.stopPropagation()
              setStatus('loading')
              beforeAddItemToCart(product, () => setStatus('loaded'))
            }}
          >
            {
              status === 'loaded' ?
                <span>加入購物車</span>
                :
                <span className="btn-loading">
                  <i className="fa fa-spinner fa-pulse"></i>
                </span>
            }
          </div>*/}
          
        </figcaption>
        
      </figure>
     
    </div>
  )
}

const mapStateToProps = (state) => {
    const { rwdInfo } = state
    return {
      rwdInfo
    }
  }
  
  const mapDispatchToProps = (dispatch) => {
  }
  
  export default connect(mapStateToProps, mapDispatchToProps)(ProductListItem)