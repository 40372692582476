import React from 'react'
import { render } from "react-dom";
import './scss/index.scss'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { BrowserRouter } from 'react-router-dom'
import { Provider } from 'react-redux'
import { createStore, applyMiddleware } from 'redux'
import userManagementApp from './reducers'
import ReduxToastr from 'react-redux-toastr'
import thunkMiddleware from "redux-thunk"

const store = createStore(userManagementApp, applyMiddleware(thunkMiddleware))

const app = (
  <React.StrictMode>
    <Provider store={store}>
      <ReduxToastr
        timeOut={2000}
        newestOnTop={false}
        preventDuplicates
        position="bottom-center"
        getState={(state) => state.toastr} // This is the default
        transitionIn="fadeIn"
        transitionOut="fadeOut"
        progressBar
        closeOnToastrClick/>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </Provider>
  </React.StrictMode>
)

const rootElement = document.getElementById("root");

render(app, rootElement);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
