import axios from 'axios'
import { serverInfo } from '../utils/constant'
import _get from 'lodash/get'
import { getWebConfig, getProductListByKeywords } from '../utils/httpClient'

axios.defaults.withCredentials = true

const fetchProductListByGroup = (id) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axios({
        method: 'get',
        baseURL: serverInfo.newBaseUrl,
        url: '/oig',
        params: {
          oic_id: id
        }
      })
      .then(result => {
        const newProductList = _get(result, 'data.data', null)
        if (!Array.isArray(newProductList)) {
            return reject()
        }
        dispatch({
          type: 'FETCH_PRODUCT_SUCCESS',
          productList: newProductList,
        })
        resolve()
      })
      .catch(error => {
        console.error('fetch product list failed', error)
        reject()
      })
    })
  }
}

const fetchProductListByKeywords = (keywords) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      getProductListByKeywords(keywords)
        .then(result => {
          const newProductList = _get(result, 'data', null)
          if (!Array.isArray(newProductList)) {
              return reject()
          }
          dispatch({
            type: 'FETCH_PRODUCT_SUCCESS',
            productList: newProductList,
          })
          resolve()
        })
        .catch(error => {
          console.error('fetch product list failed', error)
          reject()
        })
    })
  }
}

const fetchCartItems = () => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axios({
        method: 'get',
        baseURL: serverInfo.baseUrl,
        url: '/cus/api/get-cart',
        params: {
          _: new Date().valueOf()
        }
      })
      .then(result => {
        resolve()
        dispatch({
          type: 'INIT_CART_LIST',
          cart: result.data
        })
      })
      .catch(error => {
        console.error('[action.fetchCartItems]', error)
        reject()
      })
    })
  }
}

const fetchWebsiteInfo = () => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      getWebConfig()
        .then(result => {
          resolve()
          dispatch({
            type: 'SET_INFO',
            websiteInfo: result
          })
        })
        .catch(error => {
          console.error('[action.fetchWebsiteInfo]', error)
          reject()
        })
    })
  }
}

const setIsLoading = (status) => {
  return {
    type: 'SET_IS_LOADING',
    status
  }
}

const setIsInitFinish = (status) => {
  return {
    type: 'SET_IS_INIT_FINISH',
    status
  }
}

const fetchCategory = () => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axios({
        method: 'get',
        baseURL: serverInfo.newBaseUrl,
        url: '/oic'
      })
      .then(result => {
        dispatch({
          type: 'SET_CATEGORY',
          categoryList: result.data.data,
        })
        resolve()
      })
      .catch(error => console.error('fetch category list failed', error))
    })
  }
}

const setIsTop = (status) => {
  return {
    type: 'SET_IS_TOP',
    status
  }
}

const setDeviceWidth = (status) => {
  return {
    type: 'SET_DEVICE_WIDTH',
    status
  }
}

export {
  fetchProductListByKeywords,
  fetchProductListByGroup,
  fetchCartItems,
  fetchWebsiteInfo,
  setIsLoading,
  setIsInitFinish,
  fetchCategory,
  setIsTop,
  setDeviceWidth
}
