import React, { useEffect, useRef, useState } from "react"
import PropTypes from 'prop-types'

function CustomButton(props) {
  const buttonRef = useRef()
  const [isTouch, setIsTouch] = useState(false)

  useEffect(() => {
    if (!buttonRef) {
      return
    }
    const handleStart = () => {
      setIsTouch(true)
    }
    const handleEnd = () => {
      setIsTouch(false)
    }
    buttonRef.current.addEventListener('touchstart', handleStart)
    buttonRef.current.addEventListener('touchend', handleEnd)
  }
  , [buttonRef])
	
	return (
		<div className={`${props.className}${isTouch ? ' is-touch' : ''}`} onClick={props.handleClick} ref={buttonRef}>{props.text}</div>
	)
}

CustomButton.propTypes = {
	className: PropTypes.string,
	onClick: PropTypes.func,
  text: PropTypes.string
}

export default CustomButton