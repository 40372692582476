import React from 'react'
import {
  Container,
  Button
} from "react-bootstrap"
import SmoothRenderImage from './SmoothRenderImage'
import _get from 'lodash/get'

function PaymentDisplay(props) {
  const { paymentDetail } = props

  const renderUntreated = () => {
    switch (paymentDetail.mode) {
      case 'ATMTransfer':
        return (
          <div className="payment-type p-3">
            <h5 className='mb-2'>此訂單尚未付款</h5>
            <form id="credit_card_form" method="POST" action={paymentDetail.furl}>
              <Button color="primary" type="submit" className='pay-now'>
                立即取得銀行帳戶
              </Button>
              <input type="hidden" name="MerchantID" value={paymentDetail.data_list.MerchantID} />
              <input type="hidden" name="ReturnURL" value={paymentDetail.data_list.ReturnURL} />
              <input type="hidden" name="ClientBackURL" value={paymentDetail.data_list.ClientBackURL} />
              <input type="hidden" name="OrderResultURL" value={paymentDetail.data_list.OrderResultURL} />
              <input type="hidden" name="ClientRedirectURL" value={paymentDetail.data_list.ClientRedirectURL} />
              <input type="hidden" name="EncryptType" value={paymentDetail.data_list.EncryptType} />
              <input type="hidden" name="MerchantTradeNo" value={paymentDetail.data_list.MerchantTradeNo} />
              <input type="hidden" name="MerchantTradeDate" value={paymentDetail.data_list.MerchantTradeDate} />
              <input type="hidden" name="TotalAmount" value={paymentDetail.data_list.TotalAmount} />
              <input type="hidden" name="TradeDesc" value={paymentDetail.data_list.TradeDesc} />
              <input type="hidden" name="PaymentType" value={paymentDetail.data_list.PaymentType} />
              <input type="hidden" name="ItemName" value={paymentDetail.data_list.ItemName} />
              <input type="hidden" name="ChooseSubPayment" value={paymentDetail.data_list.ChooseSubPayment} />
              <input type="hidden" name="ChoosePayment" value={paymentDetail.data_list.ChoosePayment} />
              <input type="hidden" name="CheckMacValue" value={paymentDetail.data_list.CheckMacValue} />
            </form>
          </div>
        )
      case 'CreditCard':
        return (
          <div className="payment-type p-3">
            <h5 className='mb-2'>此訂單尚未付款</h5>
            <form id="credit_card_form" method="POST" action={paymentDetail.furl}>
              <Button color="primary" type="submit" className='pay-now'>
                立即付款
              </Button>
              <input type="hidden" name="MerchantID" value={paymentDetail.data_list.MerchantID} />
              <input type="hidden" name="ReturnURL" value={paymentDetail.data_list.ReturnURL} />
              <input type="hidden" name="ClientBackURL" value={paymentDetail.data_list.ClientBackURL} />
              <input type="hidden" name="OrderResultURL" value={paymentDetail.data_list.OrderResultURL} />
              <input type="hidden" name="ClientRedirectURL" value={paymentDetail.data_list.ClientRedirectURL} />
              <input type="hidden" name="EncryptType" value={paymentDetail.data_list.EncryptType} />
              <input type="hidden" name="MerchantTradeNo" value={paymentDetail.data_list.MerchantTradeNo} />
              <input type="hidden" name="MerchantTradeDate" value={paymentDetail.data_list.MerchantTradeDate} />
              <input type="hidden" name="TotalAmount" value={paymentDetail.data_list.TotalAmount} />
              <input type="hidden" name="TradeDesc" value={paymentDetail.data_list.TradeDesc} />
              <input type="hidden" name="PaymentType" value={paymentDetail.data_list.PaymentType} />
              <input type="hidden" name="ItemName" value={paymentDetail.data_list.ItemName} />
              <input type="hidden" name="ChooseSubPayment" value={paymentDetail.data_list.ChooseSubPayment} />
              <input type="hidden" name="ChoosePayment" value={paymentDetail.data_list.ChoosePayment} />
              <input type="hidden" name="CheckMacValue" value={paymentDetail.data_list.CheckMacValue} />
            </form>
          </div>
        )
      case 'Manual':
        return (
          <div className="payment-type p-3">
            <h5 className='mb-2'>此訂單尚未付款</h5>
            <label>銀行名稱(代碼)</label>
            <div className='mb-1 opacity-80'>{`${_get(paymentDetail, 'bank_info_manual.bankname', '無') || '無'}(${_get(paymentDetail, 'bank_info_manual.bankcode', '無') || '無'})`}</div>
            <label>銀行帳號</label>
            <div className='mb-1 opacity-80'>{_get(paymentDetail, 'bank_info_manual.bankaccount', '無') || '無'}</div>
            <label>轉帳務必備註</label>
            <div className='mb-1 opacity-80'>{_get(paymentDetail, 'bank_info_manual.banknotes', '無') || '無'}</div>
            <SmoothRenderImage src={_get(paymentDetail, 'bank_info_manual.bankimage', '')} />
          </div>
        )
      case 'ReceivedPay':
      default:
        break
    }
  }

  if (paymentDetail.st === 'untreated' || paymentDetail.st === 'postPay') {
    return renderUntreated()
  } else if (paymentDetail.st === 'checking') {
    return (
      <div className="payment-checking p-3">
        <div className="load-container">
          <div className="loader"></div>
        </div>
        <h5 className='mb-2'>確認付款狀態中...</h5>
      </div>
    )
  } else if (paymentDetail.st === 'fail') {
    return (
      <div className="payment-type p-3">
        <div className="fail-icon-container">
          <SmoothRenderImage className='fail-icon' src="../assets/img/fail.png" />
        </div>
        <h5 className='mb-2'>付款失敗，請洽客服人員。</h5>
      </div>
    )
  } else {
    return <></>
  }


}

export default PaymentDisplay