import _remove from 'lodash/remove'

const defaultState = {
  itemList: [],
  sysFree: [],
  shipment: '0',
  total: '0'
}

const cart = (state = defaultState, action) => {
  switch (action.type) {
    case 'INIT_CART_LIST':
      const sysFree = _remove(action.cart.item, item => item.type === 'SYS')
      return {
        itemList: action.cart.item || [],
        sysFree,
        shipment: action.cart.shipment || '0',
        total: action.cart.total || '0'
      }
    /*case 'MODIFY_PRODUCT_NUM_IN_CART':
      state.productList = state.productList.map(item => {
        if (item.product.itemid === action.id) {
          item.count = action.count
        }
        return item
      })
      return { ...state }
    case 'ADD_PRODUCT_TO_CART':
      state.productList.push({ product: action.product, count: 1 })
      return { ...state }
    case 'REMOVE_PRODUCT_FROM_CART':
      _remove(state.productList, item => item.product.itemid === action.id)
      return  { ...state }*/
    default:
      return state
  }
}

export default cart