import React, { useState, useEffect } from 'react'
import ImageGallery from 'react-image-gallery'
import QuantitySelector from './QuantitySelector'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import { toastr } from 'react-redux-toastr'
import Form from 'react-bootstrap/Form'
import { setItemToCart, getProductDetail } from './utils/httpClient'
import _get from 'lodash/get'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { fetchCartItems } from './actions'
import { slice } from 'lodash'

function ProductBuyingModal(props) {
  const [selectedSpec, setSelectedSpec] = useState({})
  const [productQuantity, setProductQuantity] = useState(1)
  const [goCheckout, setGoCheckout] = useState(false)
  const [specIndex, setSpecIndex] = useState(-1)
  const [isLoaded, setIsLoaded] = useState(false)
  const [productItem, setProductItem] = useState({
    oig_id: '',
    group_name: '',
    content: '',
    info: {
      desc: [],
      notice: [],
      delivery: []
    }
  })
  const history = useHistory()

  useEffect(() => {
    if (props.isShow && productItem.oig_id === props.product.oig_id) {
      setIsLoaded(true)
    }
  }, [props.isShow])

  useEffect(() => {
    if (goCheckout) {
      history.push('/checkout/cartConfirm')
    }
  }, [props.cart.itemList])

  useEffect(() => {
    if (!props.product || !props.product.oig_id) {
      return
    }
    getProductDetail(props.product.oig_id)
    .then((result) => {
      if (typeof result.data.images === 'string') {
        result.data.images = [result.data.images]
      }
      setProductItem(result.data)
      setIsLoaded(true)
    })
    .catch((error) => console.error('[ProductDetail.getProductDetail]', error))
  }, [props.product])

  const addItemToCart = (product, showToastr = true) => {
    if (!product || !product.itemid) {
      toastr.info('請先選擇商品')
      return
    }
    const query = {
      itemid: product.itemid,
      number: productQuantity,
      action: 'ADD'
    }
    setItemToCart(query)
      .then((response) => {
        if (!response.result) {
          toastr.info('加入購物車失敗，請洽網站管理人員')
        }
        props.fetchCartItems()
        if (showToastr) {
          toastr.success('已加入購物車')
        }
        handleClose()
      })
      .catch(error => {
        toastr.info('加入購物車失敗，請洽網站管理人員')
        console.error('[ProductList.setItemToCart]', error)
        handleClose()
      })
  }

  const handleAddToCartClick = () => {
    setGoCheckout(false)
    addItemToCart({ itemid: selectedSpec.itemid })
  }

  const handleBuyNowClick = () => {
    setGoCheckout(true)
    addItemToCart({ itemid: selectedSpec.itemid }, false)
  }

  const handleClose = () => {
    setSpecIndex(-1)
    setSelectedSpec({})
    setIsLoaded(false)
    props.onClose()
  }

  const addPriceDelimiter = (number) => {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  const renderItemPrice = () => {
    if (isNaN(selectedSpec.price) && isNaN(productItem.price_min) && isNaN(productItem.price)) {
      return ""
    } else if (isNaN(selectedSpec.price)) {
      return productItem.price_min === productItem.price_max ?
        `$${addPriceDelimiter(productItem.price_min)}` :
        `$${addPriceDelimiter(productItem.price_min)}~$${addPriceDelimiter(productItem.price_max)}`
    } else {
      return `$${addPriceDelimiter(selectedSpec.price * productQuantity)}`
    }
  }

  const renderOldPrice = () => {
    const price = productItem.price_original_max * productQuantity
    if (isNaN(price)) {
      return ''
    }
    return `$${addPriceDelimiter(price)}`
  }

  const renderSpecOptions = () => {
    const items = _get(productItem, 'items', [])
    const handleSpecChange = (event) => {
      setSpecIndex(event.target.value)
      setSelectedSpec({ ...productItem.items[event.target.value] })
    }

    return (
      <Form.Control value={specIndex} as="select" onChange={handleSpecChange}>
        <option value={-1}>請選擇</option>
        {
          items.map((item, index) => {
            return <option value={index}>{item.name}</option>
          })
        }
      </Form.Control>
    )
    /*_get(productItem, 'items', []).map(item => {
      let optionClass = ['js-check', 'btn', 'btn-check', 'mr-1']
      if (!item.enable) {
        optionClass.push('disabled')
      } else if (selectedSpec.itemid === item.itemid) {
        optionClass.push('active')
      }
      optionClass = optionClass.join(' ')
      return (
        <div>
          <label className={optionClass} onClick={() => { selectSpec(item) }}>
            <input
              type='radio'
              name='option_size'
              value={item.name}
              checked=''
            />
            <span>{item.name}</span>
          </label>
        </div>
      )
    })*/
  }

  return (
    <Modal show={props.isShow && isLoaded} onHide={handleClose} id="buying-modal">
      <span className="material-icons-outlined icon-close" onClick={handleClose}>
        close
      </span>
      <Modal.Body>
        <article>
          <div className='card-body'>
            <div className='row'>
              <aside className='col-6'>
                <article className='gallery-wrap'>
                  <div className='card img-big-wrap'>
                    <ImageGallery
                      items={_get(productItem, 'images', []).map(url => { 
                        return { original: url }
                      })}
                      showThumbnails={false}
                      showFullscreenButton={false}
                      showPlayButton={false}
                      showNav={false}
                      showBullets={_get(productItem, 'images', []).length > 1}
                      autoPlay={false}
                      lazyLoad={false}
                      disableKeyDown="true"
                    />
                  </div>
                </article>
              </aside>
              <main className='col-6'>
                <article>
                  <h4 className='title mb-md-3'>{productItem.group_name}</h4>
                  <div className='mb-md-3 text-muted'>
                    <div className='brief-introduction'>{productItem.content}</div>
                  </div>
                  <div className='mb-3'>
                    <div>
                      <del className='price-old h6'>{renderOldPrice()}</del>
                    </div>
                    <var className='price h5 md-h4'>{renderItemPrice()}</var>
                    <br />
                  </div>
                  <div className='form-group d-none d-md-block'>
                    <div className='form-group quantity-select-container mb-4'>
                      <label className='text-muted'>選擇商品:</label>
                      <div className='option-container d-flex'>
                        {renderSpecOptions()}
                      </div>
                    </div>
                    <div className='form-group quantity-select-container mb-4'>
                      <label className='text-muted'>數量:</label>
                      <div className='quantity-container'>
                        <QuantitySelector upperLimit={10} lowerLimit={1} onChange={setProductQuantity} defaultValue={productQuantity} />
                      </div>
                    </div>
                  </div>
                  <div className={'button-group d-none d-md-block ' + (!selectedSpec.itemid ? 'disable-function' : '')}>
                    <Button className='text-light btn btn-light mr-1' onClick={handleBuyNowClick}>
                      立即購買
                    </Button>
                    <Button className='btn btn-light' onClick={handleAddToCartClick}>
                      加入購物車
                    </Button>
                  </div>
                </article>
              </main>
            </div>
            <div className="d-md-none">
              <div className='form-group'>
                <div className='form-group quantity-select-container mb-4'>
                  <label className='text-muted'>選擇商品:</label>
                  <div className='option-container d-flex'>
                    {renderSpecOptions()}
                  </div>
                </div>
                <div className='form-group quantity-select-container mb-4'>
                  <label className='text-muted'>數量:</label>
                  <div className='quantity-container'>
                    <QuantitySelector upperLimit={10} lowerLimit={1} onChange={setProductQuantity} defaultValue={productQuantity} />
                  </div>
                </div>
              </div>
              <div className={'button-group ' + (!selectedSpec.itemid ? 'disable-function' : '')}>
                <Button className='text-light btn btn-light mr-1' onClick={handleBuyNowClick}>
                  立即購買
                </Button>
                <Button className='btn btn-light' onClick={handleAddToCartClick}>
                  加入購物車
                </Button>
              </div>
            </div>
          </div>
        </article>
      </Modal.Body>
    </Modal>
  )
}

const mapStateToProps = state => {
  const { productList, cart } = state
  return {
    productList,
    cart
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    fetchCartItems: () => {
      dispatch(fetchCartItems())
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductBuyingModal)