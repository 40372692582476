import React from 'react'
import SmoothRenderImage from './SmoothRenderImage'
import LoadingBlock from './LoadingBlock'

function PayForm(props) {
  const paymentsIcon = {
    ReceivedPay: "../assets/img/icon/cache-on-delivery.png",
    CreditCard: "../assets/img/icon/credit-card.svg",
    ATMTransfer: "../assets/img/icon/atm.svg",
    UnionPay: "../assets/img/icon/union-pay.svg",
    ConvenienceStore: "../assets/img/icon/convenience-store.svg",
    WebATM: "../assets/img/icon/web-atm.svg",
    Manual: "../assets/img/icon/web-atm.svg"
  }

  if (props.isLoading) {
    return <LoadingBlock />
  }

  return (
    <div className='col-md-8 order-md-1'>
      <h4 className='mb-3'>選擇支付方式</h4>
      <div className="payments">
      {
        props.paymentList.map(payment => {
          return (
            <label className="webatm" onClick={() => props.handleSelectPayment(payment.pay_mode)}>
              <SmoothRenderImage src={paymentsIcon[payment.pay_mode]}
                alt="Web ATM" />
              <span>{payment.alias}</span>
            </label>
          )
        })
      }
			</div>
    </div>
  )
}

export default PayForm