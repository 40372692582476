const defaultState = {
  isTop: true,
  mode: ''
}

const rwdInfo = (state = defaultState, action) => {
  switch (action.type) {
    case 'SET_IS_TOP':
      return { ...state, isTop: action.status }
    case 'SET_DEVICE_WIDTH':
      return { ...state, deviceWidth: action.status }
    default:
      return state
  }
}

export default rwdInfo