import axios from 'axios'
import { serverInfo } from './constant'
import FacebookEventApi from './FacebookEventApi.js'

let EventTrack = undefined
axios.defaults.withCredentials = true

const getItemOrderDetail = (product) => {
  return new Promise((resolve, reject) => {
    axios({
      method: 'get',
      baseURL: serverInfo.baseUrl,
      url: '/cus/api/create-type-map',
      params: {
        itemid: product.itemid,
        number: 1,
        type: 'NOR',
        max: ''
      }
    })
    .then(result => {
      resolve(result.data)
    })
    .catch(error => {
      console.error('[httpClient] fetch order detail failed', error)
      reject()
    })
  })
}

const getWebConfig = () => {
  return new Promise((resolve, reject) => {
    axios({
      method: 'get',
      baseURL: serverInfo.newBaseUrl,
      url: '/website_config'
    })
    .then(res => {
      const { fb_pixel_id, fb_pixel_hash, fb_pixel_domain } = res.data.data

      resolve(res.data.data)
      EventTrack = new FacebookEventApi(fb_pixel_id, fb_pixel_hash, fb_pixel_domain)
      EventTrack.callPageView()
    })
    .catch(err => {
      reject()
    })
  })
}


/*old api*/
/*const setItemToCart = (query) => {
  return new Promise((resolve, reject) => {
    axios({
      method: 'get',
      baseURL: serverInfo.baseUrl,
      url: '/cus/api/get-item-dialog',
      params: query
    })
    .then(res => {
      resolve(res.data)
      EventTrack && EventTrack.callAddToCart() 
    })
    .catch(err => {
      reject()
    })
  })
}*/

const setItemToCart = (query) => {
  return new Promise((resolve, reject) => {
    axios({
      method: 'post',
      baseURL: serverInfo.newBaseUrl,
      url: '/cart',
      data: {
        itemid: query.itemid,
        number: query.number,
        action: query.action
      }
    })
    .then(res => {
      resolve(res.data)
      EventTrack && EventTrack.callAddToCart() 
    })
    .catch(err => {
      reject()
    })
  })
}
const deleteCartItem = (itemId) => {
  return new Promise((resolve, reject) => {
    axios({
      method: 'post',
      baseURL: serverInfo.baseUrl,
      url: '/cus/api/del-cart-item',
      data: {
        'item-id': itemId
      }
    })
    .then(() => resolve())
    .catch((error) => {
      console.error('[httpClient.deleteCartItem]', error)
      reject()
    })
  })
}

/*
  name: <string>
  phone: <string>
  address: <string>
  message: <string>
*/

const createOrder = (data) => {
  return new Promise((resolve, reject) => {
    axios({
      method: 'post',
      baseURL: serverInfo.baseUrl,
      url: '/cus/api/create-order',
      data: {
        name: data.name,
        email: data.email,
        phone: data.phone,
        address: data.address,
      }
    })
    .then(res => {
      /*
      o: "2JES0315181b0818"
      total: 100
      u: "/cus/finish2/2JES0315181b0818"
      */
      resolve(res.data)
      EventTrack && EventTrack.callPurchase()
    })
    .catch(err => {
      reject()
    })
  })
}

/*
  fb-token: <string>
  domain: <string>
  event_id: <string>
  currency: <string>
  value: <number>
*/

/*
  
*/

const getFinishedOrder = (orderId, phone) => {
  return new Promise((resolve, reject) => {
    axios({
      method: 'get',
      baseURL: serverInfo.newBaseUrl,
      url: '/order',
      params: {
        order_num: orderId,
        phone: phone
      }
    })
    .then(res => {
      resolve(res.data)
    })
    .catch(err => {
      reject()
    })
  })
}

const getPayment = (orderId) => {
  return new Promise((resolve, reject) => {
    axios({
      method: 'get',
      baseURL: serverInfo.baseUrl,
      url: '/cus/api/payment',
      params: {
        order_num: orderId,
        _: new Date().valueOf()
      }
    })
    .then(res => {
      resolve(res.data)
    })
    .catch(err => {
      reject()
    })
  })
}

const setPayment = (payment, orderId) => {
  return new Promise((resolve, reject) => {
    axios({
      method: 'post',
      baseURL: serverInfo.baseUrl,
      url: '/cus/api/payment/pay-mode',
      data: {
        type: payment,
        order_num: orderId
      }
    })
    .then(res => {
      /*
      o: "2JES0315181b0818"
      total: 100
      u: "/cus/finish2/2JES0315181b0818"
      */
      resolve(res.data)
    })
    .catch(err => {
      reject()
    })
  })
}

const getPaymentDetail = (orderId, href='') => {
  return new Promise((resolve, reject) => {
    axios({
      method: 'get',
      baseURL: serverInfo.newBaseUrl,
      url: '/cus/api/select-payment',
      params: {
        order_num: orderId,
        redirect_f2e_href: href.replace('#', '%23')
      }
    })
    .then(res => {
      resolve(res.data)
    })
    .catch(err => {
      reject()
    })
  })
}

const getProductDetail = (oigId) => {
  return new Promise((resolve, reject) => {
    axios({
      method: 'get',
      baseURL: serverInfo.newBaseUrl,
      url: `/oig/${oigId}`
    })
    .then(res => {
      resolve(res.data)
    })
    .catch(err => {
      reject()
    })
  })
}

const setOrderEmail = (orderId, email) => {
    return new Promise((resolve, reject) => {
      axios({
        method: 'get',
        baseURL: serverInfo.baseUrl,
        url: `/cus/api/get-order-email`,
        params: {
          order_num: orderId
        }
      }).then(() => {
        return axios({
          method: 'post',
          baseURL: serverInfo.baseUrl,
          url: `/cus/api/set-order-email`,
          data: {
            order_num: orderId,
            email: email
          }
        })
      }).then(res => {
        resolve(res.data)
      })
      .catch(err => {
        reject()
      })
    })
}

const saveCustomerData = (data) => {
  return new Promise((resolve, reject) => {
    axios({
      method: 'post',
      baseURL: serverInfo.baseUrl,
      url: `/cus/api/save-cus-data`,
      data: {
        name: data.name,
        phone: data.phone,
        address: data.address,
        note: data.note
      }
    })
    .then(res => {
      resolve(res.data)
    })
    .catch(err => {
      reject()
    })
  })
}

const getWebsiteData = () => {
  return new Promise((resolve, reject) => {
    axios({
      method: 'get',
      baseURL: serverInfo.baseUrl,
      url: `/cus/api/get-website-data`,
    })
    .then(res => {
      resolve(res.data)
    })
    .catch(err => {
      reject()
    })
  })
}

const setInvoice = (data) => {
  return new Promise((resolve, reject) => {
    axios({
      method: 'post',
      baseURL: serverInfo.baseUrl,
      url: `cus/ec-invoice/${data.orderId}`,
      data: {
        email: data.email,
        identifier: data.taxId || '',
        company: data.buyerName,
        type: data.invoiceType
      }
    })
    .then(res => {
      resolve(res.data)
    })
    .catch(err => {
      reject()
    })
  })
}

const getProductListByKeywords = (keywords) => {
  return new Promise((resolve, reject) => {
    axios({
      method: 'get',
      baseURL: serverInfo.newBaseUrl,
      url: `oig`,
      params: {
        group_name_like: keywords
      }
    })
    .then(res => {
      resolve(res.data)
    })
    .catch(err => {
      reject()
    })
  })
}

export {
  getItemOrderDetail,
  getWebsiteData,
  getWebConfig,
  setItemToCart,
  deleteCartItem,
  createOrder,
  getPayment,
  setPayment,
  getPaymentDetail,
  getFinishedOrder,
  getProductDetail,
  setOrderEmail,
  saveCustomerData,
  setInvoice,
  getProductListByKeywords
}