const defaultState = {
  info: {
    info_company: ''
  }
}

const websiteInfo = (state = defaultState, action) => {
  switch (action.type) {
    case 'SET_INFO':
      return action.websiteInfo
    default:
      return state
  }
}

export default  websiteInfo