import { serverInfo } from "./constant";

var FacebookEventApi = function (pixel, hash, domain = "") {
  var EXPIRES_DAY = 0;
  var self = this;

  self.fb_st = [];
  self.pixel = pixel;
  self.hash = hash;
  self.domain = domain;
  if (!pixel) {
      pixel = "xxxxxxxx"
  }
  self.callPageView = function () {
      console.log('[fb info]', pixel, hash, domain)
      var c = $.cookie(`fb_PageView${self.pixel}`);
      if (!c && !self.fb_st["PageView2"]) {
          self.fb_st["PageView2"] = FacebookEventApi.makeid(10);
          $.post(`${serverInfo.baseUrl}/api/fb-event/post-data/${self.pixel}/PageView`, {
              "fb-token": self.hash = hash,
              "domain": self.domain,
              "evend_id": self.fb_st["PageView2"]
          }, function (r) {
              //$.cookie('fb_PageView', self.fb_st["PageView2"], {expires: EXPIRES_DAY});
              $.cookie(`fb_PageView${self.pixel}`, self.fb_st["PageView2"], {expires: EXPIRES_DAY});
              setTimeout(function () {
                  console.log("PX PV OK " + r["fbtrace_id"])
                  fbq('track', 'PageView', '', {eventID: self.fb_st["PageView2"]});
              }, 1500);
          }, "json");
      } else {
          console.log("ARY PV:" + c);
      }
  }

  self.callAddToCart = function () {
      var c = $.cookie(`fb_AddToCart2${self.pixel}`);
      if (!c && !self.fb_st["AddToCart2"]) {
          self.fb_st["AddToCart2"] = FacebookEventApi.makeid(10);
          $.post(`${serverInfo.baseUrl}/api/fb-event/post-data/${self.pixel}/AddToCart`, {
              "fb-token": self.hash = hash,
              "domain": self.domain,
              "evend_id": self.fb_st["fb_AddToCart2"]
          }, function (r) {
              $.cookie(`fb_AddToCart2${self.pixel}`, self.fb_st["AddToCart2"], {expires: EXPIRES_DAY});
              console.log("PX ATC OK " + self.fb_st["AddToCart2"]);
              fbq('track', 'AddToCart', '', {eventID: self.fb_st["fb_AddToCart2"]});
          }, "json");
      } else {
          console.log("ARY ATC:" + c);
      }
  }

  self.callPurchase = function (callBack, r2) {
      var c = $.cookie(`fb_Purchase${self.pixel}`);
      if (!c && !self.fb_st["Purchase2"]) {
          self.fb_st["Purchase2"] = FacebookEventApi.makeid(10);
          $.post(`${serverInfo.baseUrl}/api/fb-event/post-data/${self.pixel}/Purchase`, {
              "fb-token": self.hash = hash,
              "domain": self.domain,
              "evend_id": self.fb_st["Purchase2"],
              "currency": "TWD",
              "value": r2.total,
          }, function (r) {
              console.log("PX PC OK " + r["fbtrace_id"])
              $.cookie(`fb_Purchase${self.pixel}`, self.fb_st["Purchase2"], {expires: EXPIRES_DAY});
              fbq('track', 'Purchase', {"currency": "TWD", "value": r2.total}, {eventID: self.fb_st["Purchase2"]});
              callBack(r);
          }, "json");
      } else {
          console.log("ARY PCH:" + c);
      }

  }

  FacebookEventApi.makeid = function (length) {
      var result = '';
      var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789!_#$%^&*';
      var charactersLength = characters.length;
      for (var i = 0; i < length; i++) {
          result += characters.charAt(Math.floor(Math.random() *
              charactersLength));
      }
      return result;
  }
}

export default FacebookEventApi