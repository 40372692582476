import React from 'react'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'

function ContactUs () {
  return (
    <div className="order-block">
      <div className="order-form" style={{marginTop: '60px'}}>
        <Form>
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label>姓名</Form.Label>
            <Form.Control type="text" placeholder="請輸入姓名" />
          </Form.Group>
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label>電子信箱</Form.Label>
            <Form.Control type="email" placeholder="請輸入電子信箱" />
          </Form.Group>
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label>電話</Form.Label>
            <Form.Control type="text" placeholder="請輸入電話" />
          </Form.Group>
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label>訂單編號(選填)</Form.Label>
            <Form.Control type="text" placeholder="請輸入訂單編號" />
          </Form.Group>
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label>詢問內容</Form.Label>
            <Form.Control as="textarea" rows={5} placeholder="請輸入欲詢問的內容" />
          </Form.Group>
          <Button variant="primary w-100" type="submit">
            Submit
          </Button>
        </Form>
      </div>
    </div>
  )
}

export default ContactUs