import React, { useEffect, useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { fetchProductListByGroup } from './actions'
import Slider from "react-slick";
import ProductListItem from './ProductListItem'
import ProductBuyingModal from './ProductBuyingModal'
import SmoothRenderImage from './SmoothRenderImage'

function HomePage (props) {
  const [isDragging, setIsDragging] = useState(false)
  const [slidesToShow, setSlidesToShow] = useState(3)
  const [showBuyingModal, setShowBuyingModal] = useState(false)
  const [selectedProduct, setSelectedProduct] = useState({})
  const history = useHistory()

  useEffect(() => {
    if (props.rwdInfo.deviceWidth === 'sm') {
      setSlidesToShow(2)
    } else if (props.rwdInfo.deviceWidth === 'md') {
      setSlidesToShow(3)
    } else {
      setSlidesToShow(4)
    }
  }, [props.rwdInfo.deviceWidth])

  useEffect(() => {
    if (!props.categoryList.DISCOUNT || props.categoryList.DISCOUNT.length === 0) {
      return
    }
    props.fetchProductList(props.categoryList.DISCOUNT[0].cid)
  }, [props.categoryList])

  const beforeAddItemToCart = (product) => {
    openBuyingModal(product)
  }

  const openBuyingModal = (product) => {
    setSelectedProduct(product)
    setShowBuyingModal(true)
  }

  const closeBuyingModal = (product) => {
    setShowBuyingModal(false)
  }

  const renderNewArrivalBlock = () => {
    if (!props.categoryList.NEW || props.categoryList.NEW.length === 0) {
      return
    }
    const setting = {
      slidesToShow: 1,
      slidesToScroll: 1,
      dots: true,
      infinite: true,
      autoplay: true,
      autoplaySpeed: 3000,
      nextArrow: (
        <SampleNextArrow  />
      ),
      prevArrow: (
        <SamplePrevArrow />
      ),
    }
    const dataList = props.categoryList.NEW.map(category => {
        const linkUrl = `/productList/cate_id=${category.cid}/${category.cate_name}`
        return {
            ...category,
            linkUrl
        }
    })

    return (
      <section className='welcome_area background-overlay'>
        <Slider {...setting}>
          {
            dataList.map(data => {
              return (
                <div onClick={() => history.push(data.linkUrl)}>
                  <SmoothRenderImage
                      className="d-flex w-100"
                      src={props.rwdInfo.deviceWidth === 'sm' ? data.images_mobile : data.images}
                  />
                  {/* <div className='hero-content'>
                    <h6>{data.cate_name_sub}</h6>
                    <h2>{data.cate_name}</h2>    
                  </div> */}
                </div>
              )
            })
          }
        </Slider>
      </section>
    )
  }

  const renderDiscountBlock = () => {
    if (!props.categoryList.DISCOUNT || props.categoryList.DISCOUNT.length === 0 || props.productList.length === 0) {
      return
    }

    const setting = {
      slidesToShow,
      slidesToScroll: 1,
      adaptiveHeight: true,
      variableWidth: false,
      nextArrow: (
        <SampleNextArrow  />
      ),
      prevArrow: (
        <SamplePrevArrow />
      ),
      beforeChange: () => setIsDragging(true),
      afterChange: () => setIsDragging(false),
    }
    const category = props.categoryList.DISCOUNT[0]
    const linkUrl = `/productList/cate_id=${category.cid}/${category.cate_name}`
    return (
        <section className='discount-area background-overlay mt-7 pr-xl-5 pl-xl-6'>
          <Link to={linkUrl}>
            <div className='hero-content'>
              <h6>{category.cate_name_sub}</h6>
              <h2>{category.cate_name}</h2>
            </div>
          </Link>
          <Slider {...setting}>
            {
              props.productList.map(product => {
                return <ProductListItem product={product} beforeAddItemToCart={beforeAddItemToCart} className='justify-content-center d-flex mr-2 ml-2' preventClick={isDragging} />
              })
            }
          </Slider>
        </section>
    )
  }

  const renderCategoryBlock = () => {
    if (!props.categoryList.CATE || props.categoryList.CATE.length === 0) {
      return
    }
    return (
      <div className='top_catagory_area clearfix mt-7'>
        <div>
          <div className='row justify-content-center'>
          {
            props.categoryList.CATE.map(category => {
              const productListUrl = `/productList/cate_id=${category.cid}/${category.cate_name}`
              const backgroundImage = `url(${category.images})`

              return (
                  <div className='col-6 col-md-3 pr-2 pl-2'>
                    <Link to={productListUrl}>
                      <div
                        className='single_catagory_area d-flex align-items-center justify-content-center bg-img'
                        style={{ backgroundImage }}
                      >
                        <div className='catagory-content'>
                            {category.cate_name}
                        </div>
                      </div>
                    </Link>
                  </div>
               
              )
            })
          }
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className='home-page'>
      <ProductBuyingModal isShow={showBuyingModal} product={selectedProduct} onClose={closeBuyingModal} />
      {renderNewArrivalBlock()}
      {renderDiscountBlock()}
      {renderCategoryBlock()}
    </div>
  )
}
HomePage.propTypes = {
  websiteInfo: PropTypes.object.isRequired
}

const mapStateToProps = (state) => {
  const { websiteInfo, categoryList, productList, rwdInfo } = state
  return {
    websiteInfo,
    categoryList,
    productList,
    rwdInfo
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    fetchProductList: (oicId) => {
      return new Promise((resolve, reject) => {
        dispatch(fetchProductListByGroup(oicId))
        .then(() => {
          resolve()
        })
      })
    },
  }
}

function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <span
      className='material-icons-outlined slick-next d-none d-xl-flex'
      onClick={onClick}
    >      
      arrow_forward_ios
    </span>
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <span
      className='material-icons-outlined slick-prev d-none d-xl-flex'
      onClick={onClick}
    >      
      arrow_back_ios
    </span>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(HomePage)