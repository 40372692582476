import React, { useState, useEffect } from 'react'
import Image from 'react-bootstrap/Image'
import YouTube from 'react-youtube'
import PropTypes from 'prop-types'

function AdvertisingBoard(props) {
  const opts = {
    height: '',
    width: '100%',
  }
  const youtube_parser = (url) => {
    var regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
    var match = url.match(regExp);
    return (match&&match[7].length==11)? match[7] : false;
  }

  return (
    <div className={props.className}>
      {props.advertisingData.map(item => {
        if (item.content_type === 'YOUTUBE') {
          return (
            <div className="container">
              <YouTube
                videoId={youtube_parser(item.content)}
                containerClassName="youtube-container"
                opts={opts}
              />
            </div>
          )
        } else if (item.content_type === 'IMAGE') {
          return (
            <div className="container">
              <Image src={item.content} />
            </div>
          )
        } else {
          return (
            <div className='container'>
              {item.content}
            </div>
          )
        }
      })}
    </div>
  )
}

AdvertisingBoard.propTypes = {
  advertisingData: PropTypes.array
}

export default AdvertisingBoard
