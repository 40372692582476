import { faRegistered } from '@fortawesome/free-solid-svg-icons'
import React, { useEffect, useState, useRef } from 'react'
import { getWebsiteData, saveCustomerData } from './utils/httpClient'

function BillForm(props) {
  const refBillForm = useRef()

  const [billInfo, setBillInfo] = useState({
    name: '',
    phone: '',
    email: '',
    address: '',
    zip: '',
    invoiceType: '1',
    taxId: '',
    buyerName: ''
  })

  const handleInputBlur = () => {
    saveCustomerData(billInfo)
  }

  const handleSubmit = (event) => {
    refBillForm.current.classList.add('was-validated')
    if (!refBillForm.current.checkValidity()) {
      return
    }
    props.confirmBillInfo(billInfo)
  }

  const validateEmail = (email) => {
    const regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return regex.test(String(email).toLowerCase());
  }
  
  useEffect(() => {
    getWebsiteData()
      .then(result => {
        setBillInfo({ ...result.cus_data, email: billInfo.email, invoiceType: billInfo.invoiceType })
      })
  }, [])

  return (
    <div className='col-md-8 order-md-1 bill-form'>
      <form className='needs-validation' onSubmit={props.confirmBillInfo} novalidate ref={refBillForm}>
        <div className='mb-3'>
          <label>收件人姓名</label>
          <input
            type='text'
            className='form-control'
            id='firstName'
            placeholder=''
            value={billInfo.name}
            onChange={(event) => setBillInfo({ ...billInfo, name: event.target.value })}
            required
            onBlur={handleInputBlur}
          />
          <div className='invalid-feedback'>
            請填入收件人姓名
          </div>
        </div>
        <div className='mb-3'>
          <label>收件人電話</label>
          <input
            type='text'
            className='form-control'
            id='phone'
            placeholder=''
            value={billInfo.phone}
            onChange={(event) => setBillInfo({ ...billInfo, phone: event.target.value })}
            required
            onBlur={handleInputBlur}
          />
          <div className='invalid-feedback'>
            請填入收件人電話
          </div>
        </div>
        <div className='mb-3'>
          <label>收件地址</label>
          <input
            type='text'
            className='form-control'
            id='address'
            value={billInfo.address}
            onChange={(event) => setBillInfo({ ...billInfo, address: event.target.value })}
            required
            onBlur={handleInputBlur}
          />
          <div className='invalid-feedback'>
            請填入收件地址
          </div>
        </div>
        <div className='mb-3'>
          <label>
            Email
            {billInfo.invoiceType !== '2' && <span className='text-muted'>(選填)</span>}
          </label>
          <input
            type='email'
            className='form-control'
            id='email'
            value={billInfo.email}
            placeholder='you@example.com'
            onChange={(event) => setBillInfo({ ...billInfo, email: event.target.value })}
            required={billInfo.invoiceType === '2'}
          />
          <div className='invalid-feedback'>
            請填入正確的Email位址
          </div>
        </div>
        <div className='mb-3'>
          <label>
            電子發票選項
          </label>
          <select className="form-control" onChange={(event) => setBillInfo({ ...billInfo, invoiceType: event.target.value })}>
            <option value='1'>愛心捐贈</option>
            <option value='2'>寄送至信箱</option>
          </select>
          <div className='form-text text-muted'>
            {
              billInfo.invoiceType === '1' ? 
                '預設愛心捐贈碼: 2805' :
                '發票將於司機送達時開出，會在3-5個工作天寄到您的信箱'
            }
          </div>
        </div>
        {
          billInfo.invoiceType === '2' ? [
            <div className='mb-3'>
              <label>
                受買人姓名
              </label>
              <input
                type='text'
                className='form-control'
                id='buyer-name'
                placeholder=''
                value={billInfo.buyerName}
                required
                onChange={(event) => setBillInfo({ ...billInfo, buyerName: event.target.value })}
              />
              <div className='invalid-feedback'>此欄影響中獎權益，請確實填寫(欲使用統一編號請輸入公司名稱)</div>
            </div>,
            <div className='mb-3'>
              <label>
                統一編號
                <span className='text-muted'>(選填)</span>
              </label>
              <input
                type='number'
                className='form-control'
                id='tax-id'
                placeholder=''
                value={billInfo.taxId}
                onChange={(event) => setBillInfo({ ...billInfo, taxId: event.target.value })}
              />
               <div className='invalid-feedback'>
                請填入正確的統一編號
              </div>
            </div>,
          ] : null
        }
        <div className='mb-3'>
          <label>
            備註
            <span className='text-muted'>(選填)</span>
          </label>
          <input
            type='text'
            className='form-control'
            id='zip'
            placeholder=''
            value={billInfo.zip}
            onChange={(event) => setBillInfo({ ...billInfo, zip: event.target.value })}
            onBlur={handleInputBlur}
          />
          <div className='invalid-feedback'></div>
        </div>
        {/*<div className='custom-control custom-checkbox'>
          <input
            type='checkbox'
            className='custom-control-input'
            id='same-address'
          />
          <label className='custom-control-label' for='same-address'>
            Shipping address is the same as my billing address
          </label>
        </div>
        <div className='custom-control custom-checkbox'>
          <input
            type='checkbox'
            className='custom-control-input'
            id='save-info'
          />
          <label className='custom-control-label' for='save-info'>
            Save this information for next time
          </label>
        </div>
        <hr className='mb-4' />*/}
        {/*<h4 className='mb-3'>Payment</h4>
        <div className='d-block my-3'>
          <div className='custom-control custom-radio'>
            <input
              id='credit'
              name='paymentMethod'
              type='radio'
              className='custom-control-input'
              checked
              required
            />
            <label className='custom-control-label' for='credit'>
              Credit card
            </label>
          </div>
          <div className='custom-control custom-radio'>
            <input
              id='debit'
              name='paymentMethod'
              type='radio'
              className='custom-control-input'
              required
            />
            <label className='custom-control-label' for='debit'>
              Debit card
            </label>
          </div>
          <div className='custom-control custom-radio'>
            <input
              id='paypal'
              name='paymentMethod'
              type='radio'
              className='custom-control-input'
              required
            />
            <label className='custom-control-label' for='paypal'>
              Paypal
            </label>
          </div>
        </div>
        <div className='row'>
          <div className='col-md-6 mb-3'>
            <label for='cc-name'>Name on card</label>
            <input
              type='text'
              className='form-control'
              id='cc-name'
              placeholder=''
              required
            />
            <small className='text-muted'>
              Full name as displayed on card
            </small>
            <div className='invalid-feedback'>
              Name on card is required
            </div>
          </div>
          <div className='col-md-6 mb-3'>
            <label for='cc-number'>Credit card number</label>
            <input
              type='text'
              className='form-control'
              id='cc-number'
              placeholder=''
              required
            />
            <div className='invalid-feedback'>
              Credit card number is required
            </div>
          </div>
        </div>
        <div className='row'>
          <div className='col-md-3 mb-3'>
            <label for='cc-expiration'>Expiration</label>
            <input
              type='text'
              className='form-control'
              id='cc-expiration'
              placeholder=''
              required
            />
            <div className='invalid-feedback'>
              Expiration date required
            </div>
          </div>
          <div className='col-md-3 mb-3'>
            <label for='cc-expiration'>CVV</label>
            <input
              type='text'
              className='form-control'
              id='cc-cvv'
              placeholder=''
              required
            />
            <div className='invalid-feedback'>
              Security code required
            </div>
          </div>
        </div>
      <hr className='mb-4' />*/}
        <div className='footer-btn'>
          <button
            className='btn btn-secondary btn-lg btn-block'
            type='button'
            onClick={props.toPreviousStep}
          >
            上一步
          </button>
          <button
            className='btn btn-primary btn-lg btn-block'
            type='button'
            onClick={handleSubmit}
          >
            下一步
          </button>
        </div>
      </form>
    </div>
  )
}

BillForm.propTypes = {
}

export default BillForm