import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { fetchCartItems } from './actions'
import { connect } from 'react-redux'
import SmoothRenderImage from './SmoothRenderImage'
import QuantitySelector from './QuantitySelector'
import Button from 'react-bootstrap/Button'
import { useHistory, useLocation } from 'react-router-dom'
import { deleteCartItem, setItemToCart } from './utils/httpClient'
import { toastr } from 'react-redux-toastr'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faShoppingCart } from '@fortawesome/free-solid-svg-icons'
import { faTrashAlt } from '@fortawesome/free-regular-svg-icons'
import cx from 'classnames'
import Modal from 'react-bootstrap/Modal'

function DropdownCart(props) {
    const history = useHistory()
    const location = useLocation()
    const [shouldConfirmModalShow, setShouldConfirmModalShow] = useState(false)
    const [queryRemoveItem, setQueryRemoveItem] = useState(null)

    const beforeRemove = (id) => {
        const query = {
            itemid: id,
            number: 0,
            action: 'UPDATE'
        }
        const leftCartItem = props.cart.itemList.filter((item) => item.item_id !== id)
        if (leftCartItem.length === 0 && location.pathname.indexOf('checkout') > - 1) {
            setShouldConfirmModalShow(true)
            setQueryRemoveItem(query)
            return
        }

        handleRemove(false, query)()
    }

    const handleRemove = (bBackToHome, query) => () => {
        if (shouldConfirmModalShow) {
            handleClose()
        }
        setItemToCart(query || queryRemoveItem)
            .then((response) => {
                if (!response.result) {
                    toastr.info('移除失敗，請洽網站管理人員')
                }
                props.fetchCartItems()
                if (bBackToHome) {
                    props.toggleCart()
                    history.push('/')
                }
            })
            .catch(error => {
                console.error('[ProductList.setItemToCart]', error)
                toastr.info('移除失敗，請洽網站管理人員')
            })
    }

    const handleCheckoutClick = () => {
        props.toggleCart()
        history.push('/checkout/cartConfirm')
    }

    const handleCartItemNumChange = (itemid) => (num) => {
        if (!itemid) {
            toastr.info('調整數量失敗，請洽網站管理人員')
            return
        }
        const query = {
            itemid: itemid,
            number: num,
            action: 'UPDATE'
        }
        setItemToCart(query)
            .then((response) => {
                if (!response.result) {
                    toastr.info('調整數量失敗，請洽網站管理人員')
                    return;
                }
                props.fetchCartItems()
            })
            .catch(error => {
                console.error('[ProductList.setItemToCart]', error)
            })
    }

    const handleClose = () => {
        setShouldConfirmModalShow(false)
    }

    return (
        <div id="dropdown-shopping-cart">
            <Modal show={shouldConfirmModalShow} onHide={handleClose}>
                <Modal.Header closeButton>
                </Modal.Header>

                <Modal.Body>
                    <p>移除購物車中最後的商品將自動回到商城首頁，確定要移除嗎?</p>
                </Modal.Body>

                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>取消</Button>
                    <Button variant="primary" onClick={handleRemove(true)}>確定</Button>
                </Modal.Footer>
            </Modal>
            <ul className="item-list">
                {props.cart.itemList.length > 0 ? (
                    props.cart.itemList.map(item => {
                        return (
                            <li className="cart-item">
                                <div className="image-container">
                                    <div className="img-wrap">
                                        <SmoothRenderImage src={item.display_img_small} />
                                    </div>
                                </div>
                                <div className="item-content">
                                    <h6>{item.form_name}</h6>
                                    <div>${item.total}</div>
                                    <div className="mt-auto">
                                        <QuantitySelector
                                            upperLimit={99}
                                            lowerLimit={1}
                                            onChange={handleCartItemNumChange(item.item_id)}
                                            defaultValue={item.num}
                                        />
                                    </div>
                                </div>
                                <div
                                    className='item_remove'
                                    onClick={() => beforeRemove(item.item_id)}
                                >
                                    <FontAwesomeIcon icon={faTrashAlt} />
                                </div>
                            </li>
                        )
                    })) : (
                    <div className='empty-cart'>
                        購物車目前無任何商品!
                    </div>
                )
                }
            </ul>
            {props.cart.itemList.length > 0 && <div className={cx("footer", { 'd-none': location.pathname.indexOf('checkout') > - 1 })}>
                <Button onClick={handleCheckoutClick}>立即結帳</Button>
            </div>}
        </div>
    )
}

DropdownCart.propTypes = {
    cart: PropTypes.object.isRequired
}

const mapStateToProps = (state) => {
    const { cart } = state
    return {
        cart
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        fetchCartItems: () => {
            dispatch(fetchCartItems())
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(DropdownCart)
