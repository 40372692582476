import React, { useRef, useState } from 'react'
import { fetchCartItems } from './actions'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Link, useHistory, useLocation } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faShoppingCart } from '@fortawesome/free-solid-svg-icons'
import { faTrashAlt } from '@fortawesome/free-regular-svg-icons'
import { deleteCartItem, setItemToCart } from './utils/httpClient'
import QuantitySelector from './QuantitySelector'
import _get from 'lodash/get'
import { toastr } from 'react-redux-toastr'
import SmoothRenderImage from './SmoothRenderImage'
import Button from 'react-bootstrap/Button'
import cx from 'classnames'
import Modal from 'react-bootstrap/Modal'

function Cart(props) {
    const history = useHistory()
    const location = useLocation()
    const [shouldConfirmModalShow, setShouldConfirmModalShow] = useState(false)
    const [queryRemoveItem, setQueryRemoveItem] = useState(null)

    const beforeRemove = (id) => {
        const query = {
            itemid: id,
            number: 0,
            action: 'UPDATE'
        }
        const leftCartItem = props.cart.itemList.filter((item) => item.item_id !== id)
        if (leftCartItem.length === 0 && location.pathname.indexOf('checkout') > - 1) {
            setShouldConfirmModalShow(true)
            setQueryRemoveItem(query)
            return
        }

        handleRemove(false, query)()
    }

    const handleRemove = (bBackToHome, query) => () => {
        if (shouldConfirmModalShow) {
            handleClose()
        }
        setItemToCart(query || queryRemoveItem)
            .then((response) => {
                if (!response.result) {
                    toastr.info('移除失敗，請洽網站管理人員')
                }
                props.fetchCartItems()
                if (bBackToHome) {
                    props.toggleCart()
                    history.push('/')
                }
            })
            .catch(error => {
                console.error('[ProductList.setItemToCart]', error)
            })
    }

    const handleCheckoutClick = () => {
        props.toggleCart()
        history.push('/checkout/cartConfirm')
    }

    const handleCartItemNumChange = (itemid) => (num) => {
        if (!itemid) {
            toastr.info('調整數量失敗，請洽網站管理人員')
            return
        }
        const query = {
            itemid: itemid,
            number: num,
            action: 'UPDATE'
        }
        setItemToCart(query)
            .then((response) => {
                if (!response.result) {
                    toastr.info('調整數量失敗，請洽網站管理人員')
                    return;
                }
                props.fetchCartItems()
            })
            .catch(error => {
                console.error('[ProductList.setItemToCart]', error)
            })
    }

    const handleClose = () => {
        setShouldConfirmModalShow(false)
    }

    return (
        <div className={'right-side-cart-area' + props.cartCls}>
            <Modal show={shouldConfirmModalShow} onHide={handleClose}>
                <Modal.Header closeButton>
                </Modal.Header>

                <Modal.Body>
                    <p>移除購物車中最後的商品將自動回到商城首頁，確定要移除嗎?</p>
                </Modal.Body>

                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>取消</Button>
                    <Button variant="primary" onClick={handleRemove(true)}>確定</Button>
                </Modal.Footer>
            </Modal>
            <a id='rightSideCart' onClick={props.toggleCart} className="cart-button eventag">
                <span class="off">CLOSE <span>
                    <svg class="svg-inline--fa fa-angle-down" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="angle-down" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" data-fa-i2svg=""><path fill="currentColor" d="M143 352.3L7 216.3c-9.4-9.4-9.4-24.6 0-33.9l22.6-22.6c9.4-9.4 24.6-9.4 33.9 0l96.4 96.4 96.4-96.4c9.4-9.4 24.6-9.4 33.9 0l22.6 22.6c9.4 9.4 9.4 24.6 0 33.9l-136 136c-9.2 9.4-24.4 9.4-33.8 0z"></path></svg></span></span>
            </a>
            <div className='cart_lists'>
                <div className='cart_list_wrap'>
                    <div className='cart_responsive'>
                        <ul className="item-list">
                            {props.cart.itemList.length > 0 ? (
                                props.cart.itemList.map(item => {
                                    return (
                                        <li className="cart-item">
                                            <div className="image-container">
                                                <div className="img-wrap">
                                                    <SmoothRenderImage src={item.display_img_small} />
                                                </div>
                                            </div>
                                            <div className="item-content">
                                                <h6>{item.form_name}</h6>
                                                <div>${item.total}</div>
                                                <div className="mt-auto">
                                                    <QuantitySelector
                                                        upperLimit={99}
                                                        lowerLimit={1}
                                                        onChange={handleCartItemNumChange(item.item_id)}
                                                        defaultValue={item.num}
                                                    />
                                                </div>
                                            </div>
                                            <div
                                                className='td_item item_remove'
                                                onClick={() => beforeRemove(item.item_id)}
                                            >
                                                <FontAwesomeIcon icon={faTrashAlt} />
                                            </div>
                                        </li>
                                    )
                                })) : (
                                <div className='empty-cart'>
                                    購物車目前無任何商品!
                                </div>
                            )
                            }
                        </ul>
                    </div>
                    {props.cart.itemList.length > 0 && <div className={cx("cart-footer", { 'd-none': location.pathname.indexOf('checkout') > - 1 })}>
                        <Button onClick={handleCheckoutClick}>立即結帳</Button>
                    </div>}
                </div>
            </div>
        </div>
    )
}

Cart.propTypes = {
    cart: PropTypes.object.isRequired
}

const mapStateToProps = (state) => {
    const { cart } = state
    return {
        cart
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        fetchCartItems: () => {
            dispatch(fetchCartItems())
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Cart)
