import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus, faMinus  } from '@fortawesome/free-solid-svg-icons'

function QuantitySelector(props) {
  const increment = () => {
    if (props.defaultValue === props.upperLimit) {
      return 
    }
    const newQuantity = props.defaultValue + 1
    props.onChange(newQuantity)
  }
  
  const decrement = () => {
    if (props.defaultValue === props.lowerLimit) {
      return
    }
    const newQuantity = props.defaultValue - 1
    props.onChange(newQuantity)
  }
    
  return (
    <div className='quantity-selector'>
      <div className="quantity-input">
        <button className="quantity-input__modifier quantity-input__modifier--left" onClick={decrement}>
          <FontAwesomeIcon icon={faMinus} />
        </button>
        <input className="quantity-input__screen" type="text" value={props.defaultValue} readOnly />
        <button className="quantity-input__modifier quantity-input__modifier--right" onClick={increment}>
          <FontAwesomeIcon icon={faPlus} />
        </button>
      </div>  
    </div>
  )
}

QuantitySelector.propTypes = {
  upperLimit: PropTypes.number,
  lowerLimit: PropTypes.number,
  defaultValue: PropTypes.number,
  onChange: PropTypes.func
}

export default QuantitySelector