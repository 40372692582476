import { combineReducers } from 'redux'
import productList from './productList'
import websiteInfo from './websiteInfo'
import cart from './cart'
import { reducer as toastrReducer } from 'react-redux-toastr'
import isLoading from './isLoading'
import isInitFinish from './isInitFinish'
import categoryList from './categoryList'
import rwdInfo from './rwdInfo'

const shoppingApp = combineReducers({
  productList,
  websiteInfo,
  cart,
  toastr: toastrReducer,
  isLoading,
  isInitFinish,
  categoryList,
  rwdInfo
})

export default shoppingApp
