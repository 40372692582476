import React from 'react'

function WebMaintainPage() {
  return (
    <div className="main-wrapper">
      <div className="signboard-wrapper">
        <div className="signboard">CLOSED</div>
        <div className="string"></div>
        <div className="pin pin1"></div>
        <div className="pin pin2"></div>
        <div className="pin pin3"></div>
      </div>
      <h4 className="mt-3">Oops...網站維護中，我們將盡快恢復服務。</h4>
    </div>
  )
}

export default WebMaintainPage