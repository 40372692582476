import React, { useEffect, useRef, useState, useCallback } from 'react'
import _get from 'lodash/get'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { getFinishedOrder, getPaymentDetail } from './utils/httpClient'
import { useParams, useHistory } from 'react-router-dom'
import LoadingBlock from './LoadingBlock'
import { payments } from './utils/constant'

function OrderStatus (props) {
  const [orderDetail, setOrderDetail] = useState({})
  const [isLoading, setIsLoading] = useState(true)
  const [noResult, setNoResult] = useState(true)
  const containerCls = `order-card-list ${props.containerCls}`
  const params = useParams()
  const orderId = props.orderId || params.orderId
  const phoneNum = props.phoneNum || params.phoneNum

  useEffect(() => {
    setIsLoading(true)
    Promise.all([getFinishedOrder(orderId, phoneNum), props.paymentDetail ? props.paymentDetail : getPaymentDetail(orderId)])
      .then((results) => {
        if (!results[0].result) {
          setIsLoading(false)
          return
        }
        setNoResult(false)
        if (results[0].data.length === 1) {
          results[0].data[0].payment.paymentType = results[1].pay_name
          setOrderDetail(results[0].data[0])
          setIsLoading(false)
        }
      })
      .catch((error) => {
        console.error('[OrderStatus.handleOrderSearchClick]', error)
        setIsLoading(false)
        setNoResult(true)
      })
  }, [])

  const renderResult = () => {
    if (!isLoading && noResult) {
      return (
        <div>無搜尋結果，請確認輸入了正確的訂單編號與電話號碼。</div>
      )
    }

    const logisticsStatus = _get(orderDetail, 'progress.status', {})
    const logisticsStatusName = _get(orderDetail, 'progress.status_name', {})

    return (
      <div className={containerCls}>
        <div className='block-title'>訂單資訊</div>
        <div className='order-card'>
          <div className='order-body'>
            <div className='d-flex justify-content-between'>
              <span>編號:</span>
              <span>{orderId}</span>
            </div>
            <div className='d-flex justify-content-between'>
              <span>建立時間:</span>
              <span>{orderDetail.payment.created_at}</span>
            </div>
            <div className='d-flex justify-content-between'>
              <span>訂單狀態:</span>
              <span>{orderDetail.process}</span>
            </div>
            <div className='d-flex justify-content-between'>
              <span>付款方式:</span>
              <span>{orderDetail.payment.paymentType}</span>
            </div>
            <div className='d-flex justify-content-between'>
              <span>付款狀態:</span>
              <span>{orderDetail.payment.status_name}</span>
            </div>
          </div>
        </div>
        <div className='block-title'>訂單狀態</div>
        <div className='mt-3 mb-3 vertical-step-bar'>
          <div className='pl-3 pr-3'>
            <ul id="progress">
              <div>
                <li><div className="node green"></div><p>{logisticsStatusName.PENDING}</p></li>
                <li><div className="divider grey"></div></li>
              </div>
              <div>
                <li><div className={logisticsStatus.TALLY ? "node green": "node grey"}></div><p>{logisticsStatusName.TALLY}</p></li>
                <li><div className="divider grey"></div></li>
              </div>
              <div>
                <li><div className={logisticsStatus.SHIPPING ? "node green": "node grey"}></div><p>{logisticsStatusName.SHIPPING}</p></li>
                <li><div className="divider grey"></div></li>
              </div>
              <div>
                <li><div className={logisticsStatus.DELIVERED ? "node green": "node grey"}></div><p>{logisticsStatusName.DELIVERED}</p></li>
              </div>
            </ul>
          </div>
        </div>
        <div className='block-title'>購買品項</div>
        <ul className='list-group mb-3'>
          {
            _get(orderDetail, 'item', []).map(item => {
              return (
                <li className='list-group-item d-flpPex justify-content-between lh-condensed'>
                  <div className='text-left'>
                    <div className='my-0'>{item.form_name}</div>
                    {
                      item.type === 'SYS' ?
                      null :
                      <small className='fade-text'>數量: {item.num}</small>
                    }
                  </div>
                  <span>${item.total}</span>
                </li>
              )
            })
          }
          <li className='list-group-item d-flex justify-content-between'>
            <span>總計:</span>
            <strong>${orderDetail.total}</strong>
          </li>
        </ul>
        <div className='block-title'>收件人資料</div>
        <table>
          <tr>
            <td><a>姓名</a></td>
            <td>{orderDetail.receiver.name}</td>
          </tr>
          <tr>
            <td><a>電話</a></td>
            <td>{orderDetail.receiver.phone}</td>
          </tr>
          <tr>
            <td><a>地址</a></td>
            <td>{orderDetail.receiver.address}</td>
          </tr>
          <tr>
            <td><a>備註</a></td>
            <td>{orderDetail.receiver.message}</td>
          </tr>
        </table>
      </div>
    )
  }

  return isLoading ?
    <LoadingBlock /> :
    renderResult()
}

OrderStatus.propTypes = {
  cart: PropTypes.object.isRequired,
  orderId: PropTypes.string,
  containerCls: PropTypes.string
}

const mapStateToProps = (state) => {
  const { cart } = state
  return {
    cart,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
   getOrderDetailById: (orderId) => {
     return dispatch(getFinishedOrder(orderId))
   }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(OrderStatus)
