import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import LoadingBlock from './LoadingBlock'
import Form from 'react-bootstrap/Form'

function OrderSearch() {
  const [keyword, setKeyword] = useState('')
  const [searchType, setSearchType] = useState('order ID')
  const history = useHistory()
  const [isLoading, setIsLoading] = useState(false)
  const handleOrderSearchClick = () => {
    if (!keyword) {
      return
    }
    const searchTypeNum = searchType === 'orderID' ? 1 : 2
    history.push(`/orderStatus/${searchTypeNum}/${keyword}`)
    
  }

  const handleSearchTypeChange = (event) => {
    setSearchType(event.target.value)
  }

  const handleKeywordChange = (event) => {
    setKeyword(event.target.value)
  }

  useEffect(() => {
    if (isLoading) {
      history.push(`/orderStatus/${keyword}/`)
    }
  }, [isLoading])

  return isLoading ? 
    <LoadingBlock /> :
    (
      <div className="order-block">
        <div className="order-form">
          <div className="order-form-title">請輸入您欲查詢的訂單資訊：</div>
          <div className="form-radio">
            <div className="form-check">
              <input className="form-check-input" type="radio" value="order ID" id="flexRadioDefault1" checked={searchType === 'order ID'} onChange={handleSearchTypeChange} />
              <label className="form-check-label" for="flexRadioDefault1">
                訂單編號
              </label>
            </div>
            <div className="form-check">
              <input className="form-check-input" type="radio" value="phone number" id="flexRadioDefault2" checked={searchType === 'phone number'} onChange={handleSearchTypeChange} />
              <label className="form-check-label" for="flexRadioDefault2">
                電話號碼
              </label>
            </div>
          </div>
          <input type="text" id="Order" className="order-form-input" placeholder={searchType === 'order ID' ? '訂單編號' : '電話號碼'} onChange={handleKeywordChange} value={keyword} />
          <button className="btn btn-block btn-primary" onClick={handleOrderSearchClick}>搜尋</button>
        </div>
      </div>
    )
}

export default OrderSearch