import React, { useState, useEffect } from 'react'
import { createOrder, getPayment, setPayment, getPaymentDetail, setOrderEmail, setInvoice, getFinishedOrder } from './utils/httpClient'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import {
  Link,
  useHistory,
  useRouteMatch,
  Switch,
  Route
} from 'react-router-dom'
import ProgressBar from './ProgressBar'
import BillForm from './BillForm'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons'
import { setIsLoading, fetchCartItems } from './actions'
import PayForm from './PayForm'
import _get from 'lodash/get'
import OrderStatus from './OrderStatus'

function CartConfirm(props) {
  const history = useHistory()
  return (
    <div className='col-md-8 mb-4 cart-confirm'>
      <ul className='list-group mb-3'>
        {
          _get(props, 'cart.itemList', []).map(item => {
            return (
              <li className='list-group-item d-flpPex justify-content-between lh-condensed'>
                <div>
                  <h6 className='my-0'>{item.form_name}</h6>
                  <small className='text-muted'>數量: {item.num}</small>
                </div>
                <span className='text-muted'>${item.total}</span>
              </li>
            )
          })
        }
        {
          props.cart.sysFree.map(item => {
            return (
              <li className='list-group-item d-flpPex justify-content-between lh-condensed'>
                <div>
                  <h6 className='my-0'>{item.form_name}</h6>
                </div>
                <span className='text-muted'>${item.total}</span>
              </li>
            )
          })
        }
        {/*<li className='list-group-item d-flex justify-content-between bg-light'>
                <div className='text-success'>
                  <h6 className='my-0'>Promo code</h6>
                </div>
                <span className='text-success'>-$5</span>
              </li>*/}
        <li className='list-group-item d-flex justify-content-between'>
          <span>總計:</span>
          <strong>${props.cart.total}</strong>
        </li>
      </ul>
      {/*<form className='card p-2'>
              <div className='input-group'>
                <input
                  type='text'
                  className='form-control'
                  placeholder='Promo code'
                />
                <div className='input-group-append'>
                  <button type='button' className='btn btn-secondary'>
                    Redeem
                  </button>
                </div>
              </div>
            </form>*/}
      <div className='footer-btn'>
        <button
          className='btn btn-primary btn-lg btn-block'
          type='button'
          onClick={() => history.push('/checkout/customerInfo')}
        >
          下一步
        </button>
      </div>
    </div>
  )
}

const mapStateToProps = (state) => {
  const { cart } = state
  return {
    cart
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setIsLoading: (status) => {
      dispatch(setIsLoading(status))
    },
    fetchCartItems: () => {
      dispatch(fetchCartItems())
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CartConfirm)