import React, { useEffect, useState } from 'react'
import PropTypes, { element } from 'prop-types'
import { connect } from 'react-redux'
import { useParams, useHistory } from 'react-router-dom'
import { getProductDetail, getItemOrderDetail, setItemToCart } from './utils/httpClient'
import { toastr } from 'react-redux-toastr'
import AdvertisingBoard from './AdvertisingBoard'
import _get from 'lodash/get'
import ImageGallery from 'react-image-gallery'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import {
  fetchCartItems
} from './actions'
import QuantitySelector from './QuantitySelector'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons'
import CustomButton from './CustomButton'
import SmoothRenderImage from './SmoothRenderImage'

const noInfo = '目前尚無任何資訊'

function ProductDetail (props) {
  const { id } = useParams()
  const history = useHistory()
  const [productItem, setProduct] = useState({
    group_name: '',
    info: {
      desc: [],
      notice: [],
      delivery: []
    }
  })
  const [selectedSpec, setSelectedSpec] = useState({})
  const [descriptionStatus, setDescriptionStatus] = useState(1)
  const [showOrderDetailOptions, setShowOrderDetailOptions] = useState(false)
  const [productOnSelectDetail, setProductOnSelectDetail] = useState(null)
  const [orderDetailAmount, setOrderDetailAmount] = useState({})
  const [productQuantity, setProductQuantity] = useState(1)
  const [showSpecDialog, setShowSpecDialog] = useState(false)
  const [goCheckout, setGoCheckout] = useState(false)
  const [specModalButtonType, setSpecModalButtonType] = useState('')

  const addPriceDelimiter = (number) => {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  useEffect(() => {
    getProductDetail(id)
      .then((result) => {
        if (typeof result.data.images === 'string') {
          result.data.images = [result.data.images]
        }
        setProduct(result.data)
      })
      .catch((error) => console.error('[ProductDetail.getProductDetail]', error))
  }, [])

  useEffect(() => {
    if (!productOnSelectDetail) {
      return
    }
    if (productOnSelectDetail.selectAmount) {
      setShowOrderDetailOptions(true)
    } else {
      addItemToCart()
    }
  }, [productOnSelectDetail])

  useEffect(() => {
    if (goCheckout) {
      history.push('/checkout/cartConfirm')
    }
  }, [props.cart.itemList])

  const addItemToCart = (product) => {
    if (!product || !product.itemid) {
      toastr.info('請先選擇商品')
      return
    }
    const query = {
      itemid: product.itemid,
      number: productQuantity,
      action: 'ADD'
    }
    setItemToCart(query)
      .then((response) => {
        if (!response.result) {
          return;
        }
        props.fetchCartItems()
        if (!goCheckout) {
          toastr.success('已加入購物車')
        }
      })
      .catch(error => {
        console.error('[ProductList.setItemToCart]', error)
      })
  }

  const onAddToCartClick = () => {
    setGoCheckout(false)
    setShowSpecDialog(false)
    addItemToCart({ itemid: selectedSpec.itemid })
  }

  const onBuyNowClick = () => {
    setGoCheckout(true)
    setShowSpecDialog(false)
    addItemToCart({ itemid: selectedSpec.itemid })
  }

  const openSpecModal = (buttonType) => () => {
    setSpecModalButtonType(buttonType)
    setShowSpecDialog(true)
  }

  const renderSpecOptions = () => {
    const selectSpec = (item) => {
      if (selectedSpec && item.itemid === selectedSpec.itemid) {
        setSelectedSpec({ itemid: -1 })
      }
      setSelectedSpec({ ...item })
    }

    return _get(productItem, 'items', []).map(item => {
      let optionClass = ['js-check', 'btn', 'btn-check', 'mr-1']
      if (!item.enable) {
        optionClass.push('disabled')
      } else if (selectedSpec.itemid === item.itemid) {
        optionClass.push('active')
      }
      optionClass = optionClass.join(' ')
      return (
        <div>
          <label className={optionClass} onClick={() => { selectSpec(item) }}>
            <input
              type='radio'
              name='option_size'
              value={item.name}
              checked=''
            />
            <span>{item.name}</span>
          </label>
        </div>
      )
    })
  }

  const renderSpecImgs = () => {
    return _get(productItem, 'items', []).map(item => {
      const imgSrc = item.display_img_small ? `${item.display_img_small}` : ''
      return (
        <div className='option'>
          <div className='img-wrap'>
            <SmoothRenderImage src={imgSrc} alt='' onError={(event) => {event.target.style.display = 'none'}} />
          </div>
        </div>
      )
    })
  }

  const renderProductDescription = () => {
    return (
      <>
        <AdvertisingBoard
          className={descriptionStatus !== 1 && 'd-none'}
          advertisingData={productItem.info.desc}
        />
        <AdvertisingBoard
           className={descriptionStatus !== 2 && 'd-none'}
          advertisingData={productItem.info.notice}
        />
        <AdvertisingBoard
           className={descriptionStatus !== 3 && 'd-none'}
          advertisingData={productItem.info.delivery}
        />
      </>
    )
  }

  const renderItemPrice = () => {
    if (isNaN(selectedSpec.price) && isNaN(productItem.price_min) && isNaN(productItem.price)) {
      return
    } else if (isNaN(selectedSpec.price)) {
      return `$${addPriceDelimiter(productItem.price_min)}~$${addPriceDelimiter(productItem.price_max)}`
    } else {
      return `$${addPriceDelimiter(selectedSpec.price * productQuantity)}`
    }
  }

  return (
    <div className='product-detail'>
      <section className='section-content padding-y bg'>
        <div className='container'>
          <article className='card'>
            <div className='card-body'>
              <div className='row'>
                <aside className='col-md-6'>
                  <article className='gallery-wrap'>
                    <div className='card img-big-wrap'>
                      <ImageGallery
                        items={_get(productItem, 'images', []).map(url => { 
                          return { original: url }
                        })}
                        showThumbnails={false}
                        showFullscreenButton={false}
                        showPlayButton={false}
                        showNav={false}
                        showBullets={_get(productItem, 'images', []).length > 1}
                        autoPlay={false}
                        lazyLoad={false}
                        disableKeyDown="true"
                      />
                    </div>
                  </article>
                </aside>
                <main className='col-md-6'>
                  <article>
                    <h4 className='title'>{productItem.group_name}</h4>
                    {/*<div>
                      <ul className='rating-stars'>
                        <li className='stars-active'>
                          <i className='fa fa-star'></i> <i className='fa fa-star'></i>
                          <i className='fa fa-star'></i>
                        </li>
                        <li>
                          <i className='fa fa-star'></i> <i className='fa fa-star'></i>
                          <i className='fa fa-star'></i> <i className='fa fa-star'></i>
                          <i className='fa fa-star'></i>
                        </li>
                      </ul>
                      <span className='label-rating mr-3 text-muted'>7/10</span>
                      <a href='#' className='btn-link  mr-3 text-muted'>
                        {' '}
                        <i className='fa fa-heart'></i> Save for later{' '}
                      </a>
                      <a href='#' className='btn-link text-muted'>
                        {' '}
                        <i className='fa fa-book-open'></i> Compare{' '}
                      </a>
                    </div>*/}
                    <hr />

                    <div className='mb-3'>
                      <div className='brief-introduction'>{productItem.briefIntroduction}</div>
                    </div>
                    <div className='form-group'>
                      <label className='text-muted'>選擇商品選項:</label>
                      <div className='option-container d-md-none' onClick={openSpecModal('加入購物車')}>
                        {renderSpecImgs()}
                      </div>
                      <div className='option-container d-none d-md-flex'>
                        {renderSpecOptions()}
                      </div>
                      <div className='form-group quantity-select-container d-none d-md-block mb-4'>
                        <label className='text-muted'>數量:</label>
                        <div className='quantity-container'>
                          <QuantitySelector upperLimit={10} lowerLimit={1} onChange={setProductQuantity} defaultValue={productQuantity} />
                        </div>
                      </div>
                    </div>
                    <div className='mb-3'>
                      <var className='price h4'>{renderItemPrice()}</var>
                      <del className='price-old h6'>${addPriceDelimiter(productItem.price_original_max * productQuantity)}</del>
                      <br />
                    </div>

                    <div className={'d-none d-md-flex button-group mb-4' + (!selectedSpec.itemid ? 'disable-function' : '')}>
                      <Button className='w-50 text-light btn btn-primary mr-1' onClick={onBuyNowClick}>
                        立即購買
                      </Button>
                      <Button className='w-50 btn btn-light' onClick={onAddToCartClick}>
                        加入購物車
                      </Button>
                    </div>
                  </article>
                </main>
              </div>
            </div>
          </article>
          {/*<article className='card mt-4'>
            <div className='additional-purchase'>
              <div className='block-title'>
                加購商品(還可加購{_get(productItem, 'additionalPurchaseNum', 0)}件)
              </div>
              <div className='item-list'>
                <div className='item'>
                  <div className='block-left'>
                    <SmoothRenderImage src='https://hinetcdn.waca.ec/uploads/shops/14796/products/c8/c81a88c00de96903f6d12634c0075394.jpg' />
                  </div>
                  <div className='block-right'>
                    <h6 className='item-name'>
                      中秋禮盒
                    </h6>
                    <div className='quantity-container'>
                      <QuantitySelector upperLimit={_get(productItem, 'additionalPurchaseNum', 0)} />
                    </div>
                    <p className='item-price'>
                      優惠價: $666
                    </p>
                  </div>
                </div>
                <div className='item'>
                  <div className='block-left'>
                    <SmoothRenderImage src='https://hinetcdn.waca.ec/uploads/shops/14796/products/c8/c81a88c00de96903f6d12634c0075394.jpg' />
                  </div>
                  <div className='block-right'>
                    <h6 className='item-name'>
                      中秋禮盒
                    </h6>
                    <div className='quantity-container'>
                      <QuantitySelector upperLimit={_get(productItem, 'additionalPurchaseNum', 0)} />
                    </div>
                    <p className='item-price'>
                      優惠價: $666
                    </p>
                  </div>
                </div>
                <div className='item'>
                  <div className='block-left'>
                    <SmoothRenderImage src='https://hinetcdn.waca.ec/uploads/shops/14796/products/c8/c81a88c00de96903f6d12634c0075394.jpg' />
                  </div>
                  <div className='block-right'>
                    <h6 className='item-name'>
                      中秋禮盒
                    </h6>
                    <div className='quantity-container'>
                      <QuantitySelector upperLimit={_get(props, 'productItem.additionalPurchaseNum', 0)} />
                    </div>
                    <p className='item-price'>
                      優惠價: $666
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </article>*/}
          <article className='card mt-4'>
            <div className='product-description-title'>
              <div className={descriptionStatus === 1 ? 'active' : null} onClick={() => setDescriptionStatus(1)}>商品描述</div>
              <div className={descriptionStatus === 2 ? 'active' : null} onClick={() => setDescriptionStatus(2)}>注意事項</div>
              <div className={descriptionStatus === 3 ? 'active' : null} onClick={() => setDescriptionStatus(3)}>運送及付款方式</div>
            </div>
            <div className='card-body product-description-content'>
              {renderProductDescription()}
            </div>
          </article>
        </div>
      </section>
      <div className='footer-button-container d-md-none'>
        <CustomButton className='go-back' handleClick={history.goBack} text={<FontAwesomeIcon icon={faArrowLeft}/>} />
        <CustomButton className='d-flex justify-content-center align-items-center left-button w-50' handleClick={openSpecModal('加入購物車')} text='加入購物車' />
        <CustomButton className='d-flex justify-content-center align-items-center right-button w-50' handleClick={openSpecModal('立即購買')} text='立即購買' />
        <Modal
          show={showSpecDialog}
          onHide={() => { setShowSpecDialog(false) }}
          dialogClassName="spec-modal"
        >
          <Modal.Header closeButton>
            <Modal.Title>確認規格</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className='form-group'>
              <label className='text-muted'>選擇商品選項:</label>
              <div className='option-container'>
                {renderSpecOptions()}
              </div>
            </div>
            <div className='form-group quantity-select-container'>
              <label className='text-muted'>數量:</label>
              <div className='quantity-container'>
                <QuantitySelector upperLimit={10} lowerLimit={1} onChange={setProductQuantity} defaultValue={productQuantity} />
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer className={selectedSpec.itemid ? null : 'disabled'} onClick={specModalButtonType === '加入購物車' ? onAddToCartClick : onBuyNowClick}>
            {specModalButtonType}
          </Modal.Footer>
        </Modal>
      </div>
    </div>
  )
}

ProductDetail.propTypes = {
  productList: PropTypes.arrayOf(PropTypes.object).isRequired,
}

const mapStateToProps = (state) => {
  const { cart } = state
  return {
    cart
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    fetchCartItems: () => {
      return dispatch(fetchCartItems())
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductDetail)
