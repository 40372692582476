import React from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'

function PageFooter(props) {
    const { websiteInfo } = props

    const websiteNote = { __html: websiteInfo.info.info_note }

    const facebookDetail = websiteInfo?.company_info?.find(item => item.key === 'fb_add_link')
    const lineDetail = websiteInfo?.company_info?.find(item => item.key === 'line_add_link')
    return (
        <footer className='footer_area clearfix'>
            <div className='container'>
                {/*<div className='row'>
          <div className='col-12 col-md-6'>
            <div className='single_widget_area d-flex mb-30'>
              <div className='footer-logo mr-50'>
                <a href='#'>Shop</a>
              </div>

              <div className='footer_menu'>
                <ul>
                  <li>
                    <a href='#'>Shop</a>
                  </li>
                  <li>
                    <a href='#'>Blog</a>
                  </li>
                  <li>
                    <a href='#'>Contact</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div className='col-12 col-md-6'>
            <div className='single_widget_area mb-30'>
              <ul className='footer_widget_menu'>
                <li>
                  <a href='#'>Order Status</a>
                </li>
                <li>
                  <a href='#'>Payment Options</a>
                </li>
                <li>
                  <a href='#'>Shipping and Delivery</a>
                </li>
                <li>
                  <a href='#'>Guides</a>
                </li>
                <li>
                  <a href='#'>Privacy Policy</a>
                </li>
                <li>
                  <a href='#'>Terms of Use</a>
                </li>
              </ul>
            </div>
          </div>
        </div>*/}
                <div className='row justify-content-center'>
                    {/*<div className='col-12 col-md-6'>
            <div className='single_widget_area'>
              <div className='footer_heading mb-30'>
                <h6>Subscribe</h6>
              </div>
              <div className='subscribtion_form'>
                <form action='#' method='post'>
                  <input
                    type='email'
                    name='mail'
                    className='mail'
                    placeholder='Your email here'
                  />
                  <button type='submit' className='submit'>
                    <i
                      className='fa fa-long-arrow-right'
                      aria-hidden='true'
                    ></i>
                  </button>
                </form>
              </div>
            </div>
          </div>*/}

                    <div className='col-12'>
                        <div className='single_widget_area d-md-flex justify-content-around'>
                            <div className='footer_customer_area'>
                                <div className="area_title">CUSTOMER</div>
                                <div className="area_item">
                                    <Link to='/orderSearch'>
                                        訂單查詢
                                    </Link>
                                </div>
                                <div className="area_item">
                                    <Link to='/contactUs'>
                                        聯絡我們
                                    </Link>
                                </div>
                            </div>
                            <div className='footer_social_area'>
                                <div className="area_title">FOLLOW US</div>
                                <a
                                    href='#'
                                    data-toggle='tooltip'
                                    data-placement='top'
                                    title='Facebook'
                                >
                                    <i className='fa fa-facebook' aria-hidden='true'></i>
                                </a>
                                <a
                                    href='#'
                                    data-toggle='tooltip'
                                    data-placement='top'
                                    title='Instagram'
                                >
                                    <i className='fa fa-instagram' aria-hidden='true'></i>
                                </a>
                                <a
                                    href='#'
                                    data-toggle='tooltip'
                                    data-placement='top'
                                    title='Twitter'
                                >
                                    <i className='fa fa-twitter' aria-hidden='true'></i>
                                </a>
                                <a
                                    href='#'
                                    data-toggle='tooltip'
                                    data-placement='top'
                                    title='Pinterest'
                                >
                                    <i className='fa fa-pinterest' aria-hidden='true'></i>
                                </a>
                                <a
                                    href='#'
                                    data-toggle='tooltip'
                                    data-placement='top'
                                    title='Youtube'
                                >
                                    <i className='fa fa-youtube-play' aria-hidden='true'></i>
                                </a>
                            </div>
                            <div className='footer_customer_area'>
                                <div className="area_title">CONTACT US</div>
                                <div className="static_item">
                                    客服電話 / {websiteInfo.info.info_phone}
                                </div>
                                <div className="static_item">
                                    上班時間 / {websiteInfo.info.info_online_service}
                                </div>
                                <div className="d-flex justify-content-center mt-2">
                                    {lineDetail &&
                                        <a href={lineDetail.value}>
                                            <img src="../assets/img/icon/icons-line.svg" />
                                        </a>}
                                    {facebookDetail &&
                                        <a href={facebookDetail.value} target='_blank'>
                                            <img src="../assets/img/icon/icons-facebook.svg" />
                                        </a>}
                                </div>
                            </div>
                        </div>
                        <div class="footer_bottom_area  mt-5">
                            <div dangerouslySetInnerHTML={websiteNote} />
                        </div>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-md-12 text-center'>
                        <p>
                            {websiteInfo.info.info_company}
                        </p>
                    </div>
                </div>
            </div>
        </footer>
    )
}

const mapStateToProps = (state) => {
    const { websiteInfo } = state
    return {
        websiteInfo
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PageFooter)