import React, { useState, useEffect } from 'react'
import { createOrder, getPayment, setPayment, getPaymentDetail, setOrderEmail, setInvoice, getFinishedOrder } from './utils/httpClient'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import {
  Link,
  useHistory,
  useRouteMatch,
  useLocation,
  Switch,
  Route,
  Redirect
} from 'react-router-dom'
import ProgressBar from './ProgressBar'
import BillForm from './BillForm'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons'
import { setIsLoading, fetchCartItems } from './actions'
import PayForm from './PayForm'
import _get from 'lodash/get'
import OrderStatus from './OrderStatus'
import CartConfirm from './CartConfirm'
import PaymentDisplay from './PaymentDisplay'
import cart from './reducers/cart'

const steps = [
  { name: "確認購物車" },
  { name: "填寫資訊" },
  { name: "完成訂單" },
] 

function Checkout(props) {
  let timer = null
  const history = useHistory()
  const match = useRouteMatch()
  const location = useLocation()
  const [phoneNum, setPhoneNum] = useState()
  const orderId = match.params.orderId
  const [paymentList, setPaymentList] = useState([])
  const [paymentDetail, setPaymentDetail] = useState({})
  const [isShowPayButton, setIsShowPayButton] = useState(false)
  const getStep = () => {
    switch(location.pathname) {
      case '/checkout/cartConfirm':
        return 1
      case '/checkout/customerInfo':
        return 2
      default:
        return 3
    }
  }
  const [step, setStep] = useState(getStep())
  const [isGettingPaymentList, setIsGettingPaymentList] = useState(step === 3 ? true : false)
  const toPreviousStep = () => {
    history.push('/checkout/cartConfirm')
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }
  let tempOrderId = null
  const confirmBillInfo = (billInfo) => {
    setPhoneNum(billInfo.phone)
    createOrder(billInfo)
      .then((result) => {
        props.fetchCartItems()
        setInvoice({ ...billInfo, orderId: result.o })
        tempOrderId = result.o
        history.push(`/checkout/${tempOrderId}`)
        window.scrollTo({ top: 0, behavior: 'smooth' })
      })
      .catch(() => props.setIsLoading(false))
  }
  const handleSelectPayment = (payment) => {
    setPayment(payment, orderId)
      .then(() => {
        return getPaymentDetail(orderId, window.location.href)
      })
      .then((result) => {
        setPaymentDetail(result)
        if (isGettingPaymentList) {
          setIsGettingPaymentList(false)
        }
        setStep(4)
        if (result.mode !== 'ReceivedPay' && result.st !== 'success') {
          setIsShowPayButton(true)
        }
        if (result.st === 'checking') {
          timer = setInterval(() => {
            getPaymentDetail(orderId, window.location.href)
              .then(result => {
                if (result.st !== 'checking') {
                  clearInterval(timer)
                }
              })
          }, 30000)
        }
        window.scrollTo({ top: 0, behavior: 'smooth' })
      })
      .catch(error => console.log('[checkout] set payment failed', error))
  }

  /*initial*/
  useEffect(() => {
    const cleanUp = () => {
      clearInterval(timer)
    }
    if (step < 3) {
      if (props.cart.itemList.length === 0) {
        history.push('/')
      }
      return cleanUp
    }
    setIsGettingPaymentList(true)
    getPayment(orderId)
      .then(result => {
        if (result.mode_list.length === 0) {
          setIsGettingPaymentList(false)
          getPaymentDetail(orderId, window.location.href)
            .then((result) => {
              setPaymentDetail(result)
              if (result.mode !== 'ReceivedPay' && result.st !== 'success') {
                setIsShowPayButton(true)
              }
              setStep(4)
            })
        } else if (result.mode_list.length === 1) {
          handleSelectPayment(result.mode_list[0].pay_mode, orderId)
        } else {
          setIsGettingPaymentList(false)
          setPaymentList(result.mode_list)
          setStep(3)
        }
      })
      .catch((error) => {
        console.error('[checkout] get payment failed', error)
        history.push('/')
      })
      return cleanUp
  }, [])

  return (
    <div className='checkout'>
      <div className='container'>
        <div className='progress-bar-container'>
          <ProgressBar steps={steps} currentStep={step} showNumber />
        </div>
        <div className='row'>
          <Switch>
            <Route path={`${match.path}cartConfirm`}>
              <CartConfirm />
            </Route>
            <Route path={`${match.path}customerInfo`}>
              <BillForm toPreviousStep={toPreviousStep} confirmBillInfo={confirmBillInfo} />
            </Route>
            <Route path={`${match.path}:orderId`}>
              {step === 3 && <PayForm paymentList={paymentList} handleSelectPayment={handleSelectPayment} isLoading={isGettingPaymentList} />}
              {step === 4 && <div className='complete-page'>
                <FontAwesomeIcon icon={faCheckCircle} />
                <h5>訂單已完成</h5>
                <p>歡迎再度光臨</p>
                {isShowPayButton && <PaymentDisplay paymentDetail={paymentDetail} />}
                <OrderStatus phoneNum={phoneNum} orderId={orderId} containerCls='order-detail' paymentDetail={paymentDetail} />
                <div className='footer-button-container'>
                  <Link to='/'>
                    <button className='btn btn-primary btn-lg btn-block'>繼續購物</button>
                  </Link>
                </div>
              </div>}
            </Route>
            {/*<Redirect to='/' />*/}
          </Switch>
        </div>
        {/*<footer className='my-5 pt-5 text-muted text-center text-small'>
          <p className='mb-1'>&copy; 2020-2021 therichpost.com</p>
          <ul className='list-inline'>
            <li className='list-inline-item'>
              <a href='#'>Privacy</a>
            </li>
            <li className='list-inline-item'>
              <a href='#'>Terms</a>
            </li>
            <li className='list-inline-item'>
              <a href='#'>Support</a>
            </li>
          </ul>
        </footer>*/}
      </div>
    </div>
  )
}

Checkout.propTypes = {
  cart: PropTypes.object.isRequired
}

const mapStateToProps = (state) => {
  const { cart } = state
  return {
    cart
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setIsLoading: (status) => {
      dispatch(setIsLoading(status))
    },
    fetchCartItems: () => {
      dispatch(fetchCartItems())
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Checkout)
