import React from "react"
import cx from "classnames"
import useImage from "./useImage"

const SmoothRenderImage = ({ src = "", alt = "", className, onError }) => {
  const { loaded } = useImage({ src })
  return (
      <img className={cx("smooth", { loaded }) + ` ${className}`} src={src} alt={alt} onError={onError} />
  )
}

export default SmoothRenderImage
