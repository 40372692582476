import React from 'react'

function LoadingBlock() {
    return (
			<div className="loading-box">
				<div className="circular-loader" />
				<div className="loader-message">讀取中...</div>
			</div>
    )
}

export default LoadingBlock